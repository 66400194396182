import {gsap} from 'gsap'
import { nextTick } from 'vue'
function toNumber(el,binding){
    let theNumber=0

    if(binding.value==='int'){
        theNumber=parseInt(el.innerHTML)
    }else if(binding.value==='float2'){
        theNumber=Number(el.innerHTML).toFixed(2)
    }else if(binding.value==='float3'){
        theNumber=Number(el.innerHTML).toFixed(3)
    }
    
    
    el.innerHTML=0
 

    gsap.to(el,{
        innerHTML:theNumber,
        duration:1,
       onUpdate:()=>{
        if(binding.value==='int'){
            el.innerHTML=parseInt(el.innerHTML)
            
        }else if(binding.value==='float2'){
            el.innerHTML=Number(el.innerHTML).toFixed(2)
        }else if(binding.value==='float3'){
            el.innerHTML=Number(el.innerHTML).toFixed(3)
        }
       
       }
    })

}
function toIntNumber(el,binding){
    let theNumber=0

    theNumber=parseInt(binding.value)
    el.innerHTML=0
 

    gsap.to(el,{
        innerHTML:theNumber,
        duration:1,
       onUpdate:()=>{
        el.innerHTML=parseInt(el.innerHTML)
       }
    })
}
function toFloat2Number(el,binding){
    let theNumber=0

    theNumber=Number(binding.value).toFixed(2)
    el.innerHTML=0
 

    gsap.to(el,{
        innerHTML:theNumber,
        duration:1,
       onUpdate:()=>{
        el.innerHTML=Number(el.innerHTML).toFixed(2)
       }
    })
}
function toFloat3Number(el,binding){
    let theNumber=0

    theNumber=Number(binding.value).toFixed(3)
    el.innerHTML=0
 

    gsap.to(el,{
        innerHTML:theNumber,
        duration:1,
       onUpdate:()=>{
        el.innerHTML=Number(el.innerHTML).toFixed(3)
       }
    })
}

export default (app)=>{
    app.directive('toIntNumber',toIntNumber)
    app.directive('toFloat2Number',toFloat2Number)
    app.directive('toFloat3Number',toFloat3Number)


  }