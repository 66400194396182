<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="能源消耗总量" />
      <div class="box-body box-body1">
        
        <div class="media-container">
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon1.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">能源消耗总量(tce)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number="theData.nyxh_total">{{ theData.nyxh_total }}</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <div class="item-box bar-box">
      <BoxTitle titleText="碳排放强度" unit="单位:t/万元"/>
      <div class="box-body box-body3">
        <div class="chart-outer hbar-chart-outer">
        <div class="hBar-chart" ref="hBarChartsEl" v-chart-resize></div>
      </div>
      </div>
    </div>
    
 
  </div>
</template>
<script setup>
import { ref, onMounted,watch } from "vue";
import { oneBar,hBarCharts } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
const onebarChart=ref(null)
const hBarChartsEl = ref(null);
const hBarChartsEl2 = ref(null);
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 
});
const initCharts=(val)=>{
  theData.value=val

  const theHbarCharts = hBarCharts(hBarChartsEl.value,val.dwgytpf_list,"rgba(29,224,246,0)","rgba(29,224,246,1)",'碳排放强度');
}
onMounted(() => {


});
</script>
<style lang="scss" scoped>
.hbar-chart-outer{
  height: vh(680);
  margin-top:vh(20);

}
.hBar-chart{
  height: vh(1200);
}

.box-body3{
  padding-bottom:vh(30);

}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  right:0;
  bottom:0;
  z-index: 1004;
  background: rgb(29,59,125);
  
  .media-container{
    padding-bottom:vh(33);
    margin-top:vh(31);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .media-item{
     
     
      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(83);
            // height: vh(70);
            vertical-align: top;
          }
        }
      .media-info{
        min-width: vw(200);
        padding-left:vw(11);
        padding-top:vh(7);
        // margin-left:vw(8);
        .info-label{
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00CCFF;

        }
        .info-body{
          margin-top:vh(20);
          
          .info-value{
            font-size: 30px;
            line-height: 30px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            color:#FF8924;
           
          }
       
        }
      }
    }
   
  }
  
}
</style>