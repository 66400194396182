<template>
  <div class="right-aside">
    <div class="item-box bar-box">
      <BoxTitle titleText="总量指标执行率" unit="单位:家" />
      <div class="box-body box-body1">
        <div class="pie_charts-container">
          <div class="pie-chart" ref="pie3DEl2" v-chart-resize></div>
          <div class="legend-container" v-if="theData">
            <div
              class="legend-item"
              :class="{
                color1: index == 0,
                color2: index == 1,
                color3: index == 2,
                color4: index == 3,
                color5: index == 4,
                color6:index==5
              }"
              v-for="(item, index) in theData.zlzxl_list"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="单位工业增加值固废产生量" unit="单位：t/万元" />
      <div class="box-body box-body2">
        <div class="chart-outer">
          <div class="onebar-chart" ref="onebarChart2" v-chart-resize></div>

        </div>
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle
        :titleArray="['废气排放', '废水排放']"
        unit="单位：m³"
        @toggleTab="toggleTab"
      />
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="chart-outer hbar-chart-outer">
            <div class="hBar-chart" ref="hBarChartsEl" v-chart-resize></div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { oneBar, hBarCharts, lineCharts, pie3DType2 } from "../utils/echarts";
import * as echarts from "echarts/core";
import BoxTitle from "./BoxTitle.vue";
const onebarChart = ref(null);
const onebarChart2 = ref(null);
const hBarChartsEl = ref(null);
const hBarChartsEl2 = ref(null);
const pie3DEl2 = ref(null);
const linebar = ref(null);
const props = defineProps({
  data: Object,
});
let theHbarCharts = null;
let theHbarCharts2 = null;

const theData = ref(null);
const type = ref("废气排放");
watch(
  () => props.data,
  (value, oldValue) => {
    initCharts(value);
  }
);
const toggleTab = (nav) => {
  type.value = nav;
  if (nav === "废气排放") {
    // theHbarCharts = hBarCharts(hBarChartsEl.value, theData.value.fqpf_list,"rgba(27,220,181,0)","rgba(27,220,181,1)")
    console.log(theHbarCharts);
    if (theHbarCharts) {
      // setHbarOption(theHbarCharts,theData.value.fqpf_list)
      theHbarCharts = hBarCharts(
    hBarChartsEl.value,
    theData.value.fqpf_list,
    "rgba(27,220,181,0)",
    "rgba(27,220,181,1)",
    type.value
  );
    }
  }else if(nav === "废水排放"){
    if (theHbarCharts) {
      // setHbarOption(theHbarCharts,theData.value.fspf_list)
      theHbarCharts = hBarCharts(
    hBarChartsEl.value,
    theData.value.fspf_list,
    "rgba(27,220,181,0)",
    "rgba(27,220,181,1)",
    type.value
  );
    }
    }
};
const setHbarOption = (charts, data) => {
  let options = charts.getOption();
  options.series[0].name=type.value
  options.series[0].data = data.map((item, i) => {
    return {
      value: item.value,
      itemStyle: {
        barBorderRadius: 30,
        color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: "rgba(27,220,181,0)",
          },
          {
            offset: 1,
            color: "rgba(27,220,181,1)",
          },
        ]),
      },
    };
  });
  options.series[2].data = data.map((item, i) => {
    return {
      value: item.value,
      symbolPosition: "end",
    };
  });

  options.yAxis[0].data=data.map((item) => item.name)
  options.yAxis[0].axisLabel.formatter=function (params) {
            // console.log(params.slice(0,5))
           let  sliceParams=params.length>5?params.slice(0,5)+'...':params
            var index = data.map((item) => item.name).indexOf(params);
            index = index + 1;
            if (index - 1 < 3) {
              return [("{a" + index + "|" + index + "}") + "  " + sliceParams].join(
                "\n"
              );
            } else {
              return [("{b|" + index + "}") + "  " + sliceParams].join("\n");
            }
          }
  options.yAxis[1].data=data.map((item) => item.value),
  theHbarCharts.setOption(options);
};
const initCharts = (val) => {
  theData.value = val;
  theHbarCharts = hBarCharts(
    hBarChartsEl.value,
    theData.value.fqpf_list,
    "rgba(27,220,181,0)",
    "rgba(27,220,181,1)",
    type.value
  );
  console.log(theHbarCharts);
  //  theHbarCharts2 = hBarCharts(hBarChartsEl2.value, theData.value.fspf_list,"rgba(27,220,181,0)","rgba(27,220,181,1)");

  const colors = ["#15C2BB", "#0E92D1", "#044B97", "#F28211", "#7F83DC",'rgb(51,208,5)'];
  theData.value.zlzxl_list.map((item, index) => {
    item.itemStyle = {
      color: colors[index],
    };
  });
  pie3DType2(pie3DEl2.value, theData.value.zlzxl_list);
  const barColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "#1DF6C5",
    },
    {
      offset: 1,
      color: "rgba(29,246,197,0.08)",
    },
  ]);
  console.log(barColor);
  const theOnebar2 = oneBar(
    onebarChart2.value,
    {
      data: theData.value.dwzjgf_list,
      seriesName: '单位工业增加值固废产生量',
      nameRotate:30
    },
    barColor
  );
};
onMounted(() => {
  // const theHbarCharts = hBarCharts(hBarChartsEl.value);
});
</script>
<style lang="scss" scoped>
.box-body3 {
  padding-bottom: vh(20);
}
.lineBar-chart {
  height: vh(200);
}
.hbar-chart-outer{
  height: vh(230);
  margin-top: vh(10);

}
.hBar-chart {
  height: vh(1200);
}

.legend-container {
  height: vh(220);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .legend-item {
    color: #d4e2ff;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 14px;
    // margin-bottom:vh(15);
    &::before {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      margin-right: vw(5);
      border-radius: 50%;
    }
    &.color1::before {
      background: #15c2bb;
    }
    &.color2::before {
      background: #0e92d1;
    }
    &.color3::before {
      background: #044b97;
    }
    &.color4::before {
      background: #f28211;
    }
    &.color5::before {
      background: #7f83dc;
    }
    &.color6::before {
      background: rgb(51,208,5);
    }
  }
}
.box-body1 {
  // height: vh(306);
  padding-top: vh(10);
  padding-bottom: vh(10);
  .pie_charts-container {
    display: flex;
    .pie-chart {
      height: vh(220);
      width: vw(310);
    }
  }
}
.box-body2 {
  // height: vh(226);
  padding-top: vh(10);
  padding-bottom: vh(10);
  .onebar-chart {
    height: vh(250);
    width:vw(3000);
  }
}
.right-aside {
  padding-top: vh(90);
  padding-left: vw(23);
  padding-right: vw(18);
  position: fixed;
  width: vw(469);
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1004;
  background: rgb(29, 59, 125);
  .output-container {
    margin-top: vh(17);
    display: flex;
    .output-left {
      margin-top: vh(37);
      background: url(../assets/image/chanzhi_bg.png) no-repeat;
      background-size: 100% 100%;
      width: vw(180);
      height: vh(124);
      text-align: center;
      padding-top: vh(18);
      position: relative;
      .output-value {
        font-size: 37px;
        line-height: 37px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #33eeff;
      }
      .output-label {
        font-size: 18px;
        line-height: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f8feff;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: vh(19);
      }
    }
    .output-right {
      margin-left: vw(46);
      .output-item {
        width: vw(159);
        padding-bottom: vh(10);
        // height: vh(81);
        text-align: center;
        padding-top: vh(11);
        position: relative;
        &:nth-child(1) {
          background: linear-gradient(
            0deg,
            rgba(47, 200, 238, 0.3) 0%,
            rgba(47, 200, 238, 0) 100%
          );

          border-radius: 4px;
          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: vw(26);
            height: vh(2);
            background: #33eeff;
          }
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #33eeff;
            line-height: 30px;

            background: linear-gradient(
              180deg,
              #4cbaff 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &:nth-child(2) {
          margin-top: vh(20);
          background: linear-gradient(
            0deg,
            rgba(237, 169, 36, 0.3) 0%,
            rgba(237, 169, 36, 0) 100%
          );

          border-radius: 4px;
          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: vw(26);
            height: vh(2);
            background: #ff8924;
          }
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff8924;
            line-height: 30px;

            background: linear-gradient(
              180deg,
              #d2a51c 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .item-name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          margin-bottom: vh(17);
        }
        .item-value {
        }
      }
    }
  }
}
</style>