import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'animate.css'
import {fitChartSize} from '@/utils/dataUtil.js'
import directive from '@/directive/directive';
import toNumber from '@/directive/number';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app=createApp(App).use(router).use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.config.globalProperties.fitChartFont=fitChartSize
directive(app)
toNumber(app)
app.mount('#app')
