<template>
      <div class="screen-footer" >
        <BoxTitle titleText="工业用水重复利用率"  class="custom-box_title" unit="单位:%"/>
        <div class="charts-container">
          <div class="chart-outer">
            <div class="onebar-chart" ref="onebarChart2" v-chart-resize></div>

          </div>
        </div>
  </div>
</template>
<script setup>
import BoxTitle from "./BoxTitle.vue";
import gsap from 'gsap'
import { ref,onMounted,watch } from "vue";
import { oneBar } from "../utils/echarts";
import * as echarts from "echarts/core";
import {getMax} from '@/utils/utils'
const props=defineProps({
  data:Object,
  year:String
})

const theData=ref(null)

const onebarChart2 = ref(null);
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 

});
const initCharts=(val)=>{
  theData.value=val

  const barColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "#1DF6C5",
    },
    {
      offset: 1,
      color: "rgba(29,246,197,0.08)",
    },
  ]);
  console.log(barColor);
  const theOnebar2 = oneBar(
    onebarChart2.value,
    {
      data: theData.value.gygfly_list,
      seriesName: "工业用水重复利用率",
      max:100,
      nameRotate:30
    },
    barColor
  );
}
onMounted(()=>{
  
})

</script>
<style lang="scss" scoped>
.chart-outer{
 width:vw(850);
}
 
.screen-footer {
  position: fixed;
  bottom: 0px;
  width: vw(1920);
  height: vh(361);
  background: rgba(27, 48, 102, 0.9);
  text-align: center;
  .legend-container{
    display: flex;
    justify-content: space-between;
    .unit{
      font-size: 13px;
      line-height: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
  
    }
    .legend-block{
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 13px;
      &::before{
        display: inline-block;
        content: '';
        width: vw(12);
        height: vh(12);
        background: #FF8924;
        border-radius: 2px;
        vertical-align: middle;
        margin-right:vw(5);
      }
    }
  }
  .charts-container{
    margin-top:vh(50);
    display: inline-block;
  }
.custom-box_title{
  position: absolute;
  top:vh(2);
  width:vw(880);
  left:50%;
  transform: translateX(-50%);
}
.onebar-chart{
  height: vh(300);
  width:vw(3000);
}

}
</style>