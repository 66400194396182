<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="概况总览"/>
      <div class="box-body box-body1">
        <div class="area-list">
          <div class="area-item">
            <img src="../assets/image/area_bg.png" alt="">
            <div class="item-left">
              <img src="../assets/image/footer_arrow_icon.png" alt="">
              <span class="area-name">南海子片区</span>
            </div>
            <div class="item-right">
              <span class="number" v-if="theData" v-toIntNumber.bar="theData.nhpq_nums">{{ theData.nhpq_nums }}</span><span class="unit">家</span>
            </div>
          </div>
          <div class="area-item">
            <img src="../assets/image/area_bg.png" alt="">
            <div class="item-left">
              <img src="../assets/image/footer_arrow_icon.png" alt="">
              <span class="area-name">西城片区</span>
            </div>
            <div class="item-right">
              <span class="number" v-if="theData" v-toIntNumber.bar="theData.xcpq_nums">{{ theData.xcpq_nums }}</span><span class="unit">家</span>
            </div>
          </div>
        </div>
        <div class="media-container">
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/qiye_icon.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">企业数量</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toIntNumber.bar="theData.qiye_nums">{{ theData.qiye_nums }}</span><span class="info-unit">家</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/pf_icon.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">占地面积</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.zdmj_total" style="display:inline-block;max-width:100px;">{{ theData.zdmj_total }}</span><span class="info-unit">k㎡</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/hightFloor.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">高新企业</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toIntNumber.bar="theData.gxin_nums">{{ theData.gxin_nums }}</span><span class="info-unit">家</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/leaf.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">绿色工厂</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toIntNumber.bar="theData.lsgc_nums">{{ theData.lsgc_nums }}</span><span class="info-unit">家</span>
              </div>
            </div>
          </div>

        </div>
        
      </div>
    </div>

    <div class="item-box bar-box">
      <BoxTitle titleText="行业概况" unit="单位：家"/>
      <div class="box-body box-body3">
        <div class="onebar-chart" ref="onebarChart" v-chart-resize></div>
        
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { oneBar } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
const onebarChart=ref(null)
const props=defineProps({
  data:Object
})
let theOnebar=null
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 

});
const initCharts=(val)=>{
  theData.value=val
  theOnebar = oneBar(onebarChart.value, {
    data: theData.value.hylxfb_list,
    seriesName:'企业数量',
    nameRotate:30
  });

}
</script>
<style lang="scss" scoped>
  .onebar-chart{
    height: vh(280);
   
  
  }
.area-list{
  margin-top:vh(35);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  .area-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right:vw(47);
    &:first-child{
      margin-bottom:vh(30);
    }
   
    width: vw(352);
height:vh(100);
position:relative;

>img{
  position:absolute;
  width:100%;
  height: 100%;
  z-index: -1;
}
    .item-left{
      display: flex;
      align-items: center;
      padding-left:vw(20);
      img{
        width:vw(51);
        // height: 51px;
      }
      .area-name{
        margin-left:vw(10);
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #E9FBFF;
      }
    }
    .item-right{
      display: flex;
      align-items: center;
      // margin-left:vw(66);
     
      .number{
        font-size: 30px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #FF8924;
        margin-right:vw(6);
      }
      .unit{
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #E9FBFF;
      }
    }
  }
}
.box-body1{
  // height: vh(246);
}
.box-body2{
  height: vh(268);

}
.box-body3{
 
  padding:vh(10) 0;
}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  left:0;
  bottom:0;
  z-index: 1004;
  background: rgb(29,59,125);
  
  .media-container{

    margin-top:vh(48);
    display: flex;
    flex-wrap: wrap;
    min-width: 436px;
    .media-item{
      padding-left:vw(48);
      height: vh(120);
      // &:nth-child(1){
      //   margin-bottom:vh(49);
      // }
      // &:nth-child(2){
      //   margin-bottom:vh(49);
      // }
      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(73);
            // height: vh(62);
            vertical-align: top;
          }
        }
      .media-info{
        margin-left:vw(8);
        .info-label{
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00CCFF;
          line-height: 18px;
          margin-bottom:vh(20);
        }
        .info-body{
          .info-value{
            font-size: 25px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            color:#FF8924;
          }
          .info-unit{
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 18px;
            margin-left:vw(6);
          }
        }
      }
    }
   
  }
  .big-media-container{
    margin-top:vh(25);
    display: flex;
    flex-wrap: wrap;
    padding-left:vw(10);
    .media-item{
      margin-bottom:vh(14);

      &:nth-child(3){
        margin-bottom:vh(15);
      }

      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(72);
            height: vh(89);
          }
        }
      .media-info{
        margin-left:vw(22);
        width:vw(110);
        .info-label{
          font-size: 14px;
          line-height: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          margin-top:vh(18);
        }
        .info-body{
          margin-top:vh(18);
          .info-value{
            font-size: 24px;
            line-height: 24px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            background: linear-gradient(180deg, #4CBAFF 0.146484375%, #FFFFFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
          }
          
        }
      }
    }
  }
}

</style>