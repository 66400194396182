<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="资源消耗" />
      <div class="box-body box-body1">
        
        <div class="media-container">
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/icon1.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">新鲜水(t)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.xxs_total">{{ theData.xxs_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/icon2.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">循环水(t)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.xhs_total">{{ theData.xhs_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/icon3.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">中水(t) </div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.zsyl_total">{{ theData.zsyl_total }}</span>
              </div>
            </div>
          </div>
        
          
        </div>
        
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="单位用地面积产出" unit="单位:亿元/km²" />
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="lineBar-chart" ref="linecharts" v-chart-resize></div>
        </div>
       
      </div>
    </div>


  </div>
</template>
<script setup>
import { ref, onMounted,watch } from "vue";
import {lineCharts } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
import {getMax} from '@/utils/utils'
import * as echarts from "echarts/core";
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 
});
const linecharts=ref(null)
const initCharts=(val)=>{
  theData.value=val
  lineCharts(linecharts.value,{
    data: theData.value.dwyd_list ,
    max: getMax(theData.value.dwyd_list),
    seriesName:'单位用地面积产出',
    nameRotate:30
  },{lineColor:'#FF8924',areaColor:new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: "rgba(255,137,36,.8)"
    },
    {
        offset: 1,
        color: "rgba(255,137,36, 0)"
    }
], false)})
}
onMounted(() => {


});
</script>
<style lang="scss" scoped>

.lineBar-chart{
  height: vh(400);
  width:vw(1800);
  
}
.box-body3{
  padding-bottom:vh(20);

}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  left:0;
  bottom:0;
  z-index: 1004;
  background: rgb(29,59,125);
  
  .media-container{
    padding-bottom:vh(33);
    margin-top:vh(31);
    // display: flex;
    // flex-wrap: wrap;
    .media-item{
     
      margin-bottom:vh(49);
      &:nth-child(5){
        margin-bottom:0;
      }
      &:nth-child(6){
        margin-bottom:0;
      }
      &:nth-child(2n){
        .media-info{
          width:vw(119);
          
          .info-body{
           
          }
        }
      }
      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(83);
            // height: vh(70);
            vertical-align: top;
          }
        }
      .media-info{
        width:vw(135);
        padding-left:vw(11);
        padding-top:vh(7);
        // margin-left:vw(8);
        .info-label{
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00CCFF;

        }
        .info-body{
          margin-top:vh(20);
          
          .info-value{
            font-size: 25px;
            line-height: 25px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            color:#FF8924;
           
          }
       
        }
      }
    }
   
  }
  
}
</style>