import * as echarts from "echarts/core";
import "echarts-gl";
import {
  BarChart,
  PieChart,
  LineChart,
  RadarChart,
  ScatterChart,
  TreeChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
  TimelineComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getMax,getMax2 } from "@/utils/utils";
import data from "@/testData/data";
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  PieChart,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  RadarChart,
  ScatterChart,
  TreeChart,
  ToolboxComponent,
  TimelineComponent,
]);
export function pie3D(element, data, unit) {
  let pie3D = echarts.init(element);
  let selectedIndex = "";
  let hoveredIndex = "";
  let option = getPie3D(data, 0.59);
  // 生成扇形的曲面参数方程
  function getParametricEquation(
    startRatio,
    endRatio,
    isSelected,
    isHovered,
    k,
    h
  ) {
    // 计算
    const midRatio = (startRatio + endRatio) / 2;

    const startRadian = startRatio * Math.PI * 2;
    const endRadian = endRatio * Math.PI * 2;
    const midRadian = midRatio * Math.PI * 2;

    // 如果只有一个扇形，则不实现选中效果。
    if (startRatio === 0 && endRatio === 1) {
      // eslint-disable-next-line no-param-reassign
      isSelected = false;
    }

    // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
    // eslint-disable-next-line no-param-reassign
    k = typeof k !== "undefined" ? k : 1 / 3;

    // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
    const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
    const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;

    // 计算高亮效果的放大比例（未高亮，则比例为 1）
    const hoverRate = isHovered ? 1.05 : 1;

    // 返回曲面参数方程
    return {
      u: {
        min: -Math.PI,
        max: Math.PI * 3,
        step: Math.PI / 32,
      },

      v: {
        min: 0,
        max: Math.PI * 2,
        step: Math.PI / 20,
      },

      x(u, v) {
        if (u < startRadian) {
          return (
            offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        if (u > endRadian) {
          return (
            offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
      },

      y(u, v) {
        if (u < startRadian) {
          return (
            offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        if (u > endRadian) {
          return (
            offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
      },

      z(u, v) {
        if (u < -Math.PI * 0.5) {
          return Math.sin(u);
        }
        if (u > Math.PI * 2.5) {
          return Math.sin(u) * h * 0.1;
        }
        // 当前图形的高度是Z根据h（每个value的值决定的）
        return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
      },
    };
  }
  // 生成模拟 3D 饼图的配置项
  function getPie3D(pieData, internalDiameterRatio) {
    const series = [];
    // 总和
    let sumValue = 0;
    let startValue = 0;
    let endValue = 0;
    const legendData = [];
    const k =
      typeof internalDiameterRatio !== "undefined"
        ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
        : 1 / 3;

    // 为每一个饼图数据，生成一个 series-surface 配置
    for (let i = 0; i < pieData.length; i += 1) {
      sumValue += pieData[i].value;

      const seriesItem = {
        name:
          typeof pieData[i].name === "undefined"
            ? `series${i}`
            : pieData[i].name,
        type: "surface",
        parametric: true,
        wireframe: {
          show: false,
        },
        pieData: pieData[i],
        pieStatus: {
          selected: false,
          hovered: false,
          k,
        },
      };

      if (typeof pieData[i].itemStyle !== "undefined") {
        const { itemStyle } = pieData[i];

        // eslint-disable-next-line no-unused-expressions
        typeof pieData[i].itemStyle.color !== "undefined"
          ? (itemStyle.color = pieData[i].itemStyle.color)
          : null;
        // eslint-disable-next-line no-unused-expressions
        typeof pieData[i].itemStyle.opacity !== "undefined"
          ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
          : null;

        seriesItem.itemStyle = itemStyle;
      }
      series.push(seriesItem);
    }
    // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
    // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
    for (let i = 0; i < series.length; i += 1) {
      endValue = startValue + series[i].pieData.value;

      series[i].pieData.startRatio = startValue / sumValue;
      series[i].pieData.endRatio = endValue / sumValue;
      series[i].parametricEquation = getParametricEquation(
        series[i].pieData.startRatio,
        series[i].pieData.endRatio,
        false,
        false,
        k,
        // 我这里做了一个处理，使除了第一个之外的值都是10
        series[i].pieData.value === series[0].pieData.value ? 35 : 10
      );

      startValue = endValue;

      legendData.push(series[i].name);
    }
    // series.push({
    //   name: 'pie2d',
    //   type: 'pie',
    //   label: {
    //       // show:false,
    //       opacity: 1,
    //       fontSize: 13,
    //       // lineHeight: 20,
    //   },
    //   labelLine: {
    //       length: 30,
    //       length2: 30,
    //   },
    //   startAngle: -30, //起始角度，支持范围[0, 360]。
    //   clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
    //   radius: ['20%', '50%'],
    //   center: ['50%', '50%'],
    //   data: pieData,
    //   itemStyle: {
    //       opacity: 0,
    //   },
    // })

    // 准备待返回的配置项，把准备好的 legendData、series 传入。
    const option = {
      // animation: false,
      tooltip: {
        formatter: (params) => {
          if (params.seriesName !== "mouseoutSeries") {
            return `${
              params.seriesName
            }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
              params.color
            };"></span>${option.series[params.seriesIndex].pieData.value}${
              unit ? unit : ""
            }`;
          }
          return "";
        },
      },
      legend: {
        data: legendData,
        icon: "circle",
        bottom: "5%",
        textStyle: {
          color: "#D4E2FF",
        },
      },
      xAxis3D: {
        min: -1,
        max: 1,
      },
      yAxis3D: {
        min: -1,
        max: 1,
      },
      zAxis3D: {
        min: -1,
        max: 1,
      },
      grid3D: {
        show: false,
        boxHeight: 15,
        top: "-10%",
        bottom: "-10%",

        viewControl: {
          // 3d效果可以放大、旋转等，请自己去查看官方配置
          alpha: 20,
          // beta: 30,
          rotateSensitivity: 1,
          zoomSensitivity: 0,
          panSensitivity: 0,
          autoRotate: true,
          distance: 150,
        },
        // 后处理特效可以为画面添加高光、景深、环境光遮蔽（SSAO）、调色等效果。可以让整个画面更富有质感。
        postEffect: {
          // 配置这项会出现锯齿，请自己去查看官方配置有办法解决
          enable: false,
          bloom: {
            enable: true,
            bloomIntensity: 0.5,
          },
          SSAO: {
            enable: true,
            quality: "medium",
            radius: 2,
          },
          // temporalSuperSampling: {
          //   enable: true,
          // },
        },
      },
      series,
    };
    return option;
  }
  //  修正取消高亮失败的 bug
  // 监听 mouseover，近似实现高亮（放大）效果
  pie3D.on("mouseover", function (params) {
    // 准备重新渲染扇形所需的参数
    let isSelected;
    let isHovered;
    let startRatio;
    let endRatio;
    let k;
    let i;

    // 如果触发 mouseover 的扇形当前已高亮，则不做操作
    if (hoveredIndex === params.seriesIndex) {
      return;

      // 否则进行高亮及必要的取消高亮操作
    } else {
      // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
      if (hoveredIndex !== "") {
        // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
        isSelected = option.series[hoveredIndex].pieStatus.selected;
        isHovered = false;
        startRatio = option.series[hoveredIndex].pieData.startRatio;
        endRatio = option.series[hoveredIndex].pieData.endRatio;
        k = option.series[hoveredIndex].pieStatus.k;
        i =
          option.series[hoveredIndex].pieData.value ===
          option.series[0].pieData.value
            ? 35
            : 10;
        // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
        option.series[hoveredIndex].parametricEquation = getParametricEquation(
          startRatio,
          endRatio,
          isSelected,
          isHovered,
          k,
          i
        );
        option.series[hoveredIndex].pieStatus.hovered = isHovered;

        // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
        hoveredIndex = "";
      }

      // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
      if (params.seriesName !== "mouseoutSeries") {
        // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
        isSelected = option.series[params.seriesIndex].pieStatus.selected;
        isHovered = true;
        startRatio = option.series[params.seriesIndex].pieData.startRatio;
        endRatio = option.series[params.seriesIndex].pieData.endRatio;
        k = option.series[params.seriesIndex].pieStatus.k;

        // 对当前点击的扇形，执行高亮操作（对 option 更新）
        option.series[params.seriesIndex].parametricEquation =
          getParametricEquation(
            startRatio,
            endRatio,
            isSelected,
            isHovered,
            k,
            option.series[params.seriesIndex].pieData.value + 5
          );
        option.series[params.seriesIndex].pieStatus.hovered = isHovered;

        // 记录上次高亮的扇形对应的系列号 seriesIndex
        hoveredIndex = params.seriesIndex;
      }

      // 使用更新后的 option，渲染图表
      pie3D.setOption(option);
    }
  });

  // 修正取消高亮失败的 bug
  pie3D.on("globalout", function () {
    // 准备重新渲染扇形所需的参数
    let isSelected;
    let isHovered;
    let startRatio;
    let endRatio;
    let k;
    let i;
    if (hoveredIndex !== "") {
      // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
      isSelected = option.series[hoveredIndex].pieStatus.selected;
      isHovered = false;
      k = option.series[hoveredIndex].pieStatus.k;
      startRatio = option.series[hoveredIndex].pieData.startRatio;
      endRatio = option.series[hoveredIndex].pieData.endRatio;
      // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
      i =
        option.series[hoveredIndex].pieData.value ===
        option.series[0].pieData.value
          ? 35
          : 10;
      option.series[hoveredIndex].parametricEquation = getParametricEquation(
        startRatio,
        endRatio,
        isSelected,
        isHovered,
        k,
        i
      );
      option.series[hoveredIndex].pieStatus.hovered = isHovered;

      // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
      hoveredIndex = "";
    }

    // 使用更新后的 option，渲染图表
    pie3D.setOption(option);
  });

  pie3D.setOption(option);
  return pie3D;
}
export function pie3DType2(element, optionsData) {
  let pie3D = echarts.init(element);
  // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
  function getParametricEquation(
    startRatio,
    endRatio,
    isSelected,
    isHovered,
    k,
    h
  ) {
    // 计算
    const midRatio = (startRatio + endRatio) / 2;
    const startRadian = startRatio * Math.PI * 2;
    const endRadian = endRatio * Math.PI * 2;
    const midRadian = midRatio * Math.PI * 2;
    // 如果只有一个扇形，则不实现选中效果。
    if (startRatio === 0 && endRatio === 1) {
      isSelected = false;
    }
    // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
    k = 1;
    // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
    const offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
    const offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
    // 计算高亮效果的放大比例（未高亮，则比例为 1）
    const hoverRate = isHovered ? 1.05 : 1;
    // 返回曲面参数方程
    return {
      u: {
        min: -Math.PI,
        max: Math.PI * 3,
        step: Math.PI / 32,
      },
      v: {
        min: 0,
        max: Math.PI * 2,
        step: Math.PI / 20,
      },
      x: function (u, v) {
        if (u < startRadian) {
          return (
            offsetX + Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        if (u > endRadian) {
          return (
            offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
      },
      y: function (u, v) {
        if (u < startRadian) {
          return (
            offsetY + Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        if (u > endRadian) {
          return (
            offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
          );
        }
        return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
      },
      z: function (u, v) {
        if (u < -Math.PI * 0.5) {
          return Math.sin(u);
        }
        if (u > Math.PI * 2.5) {
          return Math.sin(u) * h * 0.1;
        }
        return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
      },
    };
  }

  /**
   * 绘制3d图
   * @param pieData 总数据
   * @param internalDiameterRatio:透明的空心占比
   * @param distance 视角到主体的距离
   * @param alpha 旋转角度
   * @param pieHeight 立体的高度
   * @param opacity 饼或者环的透明度
   */
  function getPie3D(
    pieData,
    internalDiameterRatio,
    distance,
    alpha,
    pieHeight,
    opacity = 1
  ) {
    const series = [];
    let sumValue = 0;
    let startValue = 0;
    let endValue = 0;
    const legendData = [];
    const k =
      typeof internalDiameterRatio !== "undefined"
        ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
        : 1 / 3;
    // 为每一个饼图数据，生成一个 series-surface 配置
    for (let i = 0; i < pieData.length; i += 1) {
      sumValue += pieData[i].value;
      const seriesItem = {
        name:
          typeof pieData[i].name === "undefined"
            ? `series${i}`
            : pieData[i].name,
        type: "surface",
        parametric: true,
        wireframe: {
          show: false,
        },
        pieData: pieData[i],
        pieStatus: {
          selected: false,
          hovered: false,
          k: k,
        },
      };
      if (typeof pieData[i].itemStyle !== "undefined") {
        const itemStyle = {};
        if (typeof pieData[i].itemStyle.color !== "undefined") {
          itemStyle.color = pieData[i].itemStyle.color;
        }
        if (typeof pieData[i].itemStyle.opacity !== "undefined") {
          itemStyle.opacity = pieData[i].itemStyle.opacity;
        }
        seriesItem.itemStyle = itemStyle;
      }
      series.push(seriesItem);
    }
    // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
    // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
    for (let i = 0; i < series.length; i += 1) {
      endValue = startValue + series[i].pieData.value;
      series[i].pieData.startRatio = startValue / sumValue;
      series[i].pieData.endRatio = endValue / sumValue;
      series[i].parametricEquation = getParametricEquation(
        series[i].pieData.startRatio,
        series[i].pieData.endRatio,
        false,
        false,
        k,
        // series[i].pieData.value 固定高度
        15
      );
      startValue = endValue;
      legendData.push(series[i].name);
    }
    return series;
  }

  const series = getPie3D(optionsData, 0.8, 240, 28, 26, 0.5);

  series.push({
    name: "pie2d",
    type: "pie",
    label: {
      textBorderWidth: 0,
      // show:false,
      opacity: 1,
      fontSize: 18,
      color: "#fff",
      textBorderColor: "#fff",
      formatter: "{d}",
      // lineHeight: 20,
    },
    labelLine: {
      length: 30,
      length2: 30,
    },
    startAngle: -30, //起始角度，支持范围[0, 360]。
    clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
    radius: ["20%", "50%"],
    center: ["50%", "50%"],
    data: optionsData,
    itemStyle: {
      opacity: 0,
    },
  });

  let option = {
    // legend: {
    //     tooltip: {
    //         show: true,
    //     },
    //     data: ['aa', 'bb', 'cc'],
    //     type: "scroll",
    //     orient: "vartical",
    //     // x: "right",
    //     top: "center",
    //     right: "15",
    //     // bottom: "0%",
    //     itemWidth: 16,
    //     itemHeight: 8,
    //     itemGap: 16,
    //     textStyle: {
    //         color: '#A3E2F4',
    //         fontSize: 12,
    //         fontWeight: 0
    //     },
    // },
    tooltip: {
      formatter: (params) => {
        if (
          params.seriesName !== "mouseoutSeries" &&
          params.seriesName !== "pie2d"
        ) {
          let bfb =
            (option.series[params.seriesIndex].pieData.endRatio -
              option.series[params.seriesIndex].pieData.startRatio) *
            100;
          return (
            `${params.seriesName}<br/>` +
            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
            `${bfb}`
          );
        }
      },
    },

    labelLine: {
      show: true,
      lineStyle: {
        color: "#7BC0CB",
      },
    },
    label: {
      show: true,
      position: "outside",
      formatter: "{b} \n{c} {d}%",
    },
    xAxis3D: {
      min: -1,
      max: 1,
    },
    yAxis3D: {
      min: -1,
      max: 1,
    },
    zAxis3D: {
      min: -1,
      max: 1,
    },
    grid3D: {
      show: false,
      // top: "-10%",
      //   bottom: "-10%",
      boxHeight: 10, // 三维笛卡尔坐标系在三维场景中的高度
      viewControl: {
        alpha: 40,
        beta: 40,
        distance: 250, //调整视角到主体的距离，类似调整zoom
        rotateSensitivity: 0, // 设置为0无法旋转
        zoomSensitivity: 0, // 设置为0无法缩放
        panSensitivity: 0, // 设置为0无法平移
        autoRotate: false, // 自动旋转
      },
    },
    series: series,
  };

  pie3D.setOption(option);
  return pie3D;
}
export function lineCharts(element, dataObj, colorObj) {
  let oneBar = echarts.init(element);
  let lineColor = colorObj ? colorObj.lineColor : "rgba(26,202,227,1)";
  let areaColor = colorObj
    ? colorObj.areaColor
    : new echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
          {
            offset: 0,
            color: "rgba(26,202,227,.3)",
          },
          {
            offset: 1,
            color: "rgba(26,202,227, 0)",
          },
        ],
        false
      );

  var xData = [],
    yData = [];
  var min = 0; // 最小值的定义
  let maxData = [];
  dataObj.data.map(function (a, b) {
    xData.push(a.name);
    if (a.value === 0) {
      yData.push(a.value + min);
    } else {
      yData.push(a.value);
    }
    if(dataObj.yMax){
      maxData.push(dataObj.yMax);
    }else{
      maxData.push(dataObj.max);
    }
 
    // yData.push((Math.random(0,1) * 100).toFixed(0));
  });
  let option = {
    tooltip: {
      trigger: "axis",
      textStyle:{
        align:'left'
      },
      axisPointer: {
        type: "line",
        lineStyle: {
          opacity: 0,
        },
      },
      // formatter: function(prams) {
      //     if (prams[0].data === min) {
      //         return "合格率：0%"
      //     } else {
      //         return "合格率：" + prams[0].data + "%"
      //     }
      // }
    },

    grid: {
      left: "0%",
      right: "0%",
      bottom: "5%",
      top: "7%",
      // height: '85%',
      containLabel: true,
      z: 22,
    },
    xAxis: [
      {
        type: "category",
        gridIndex: 0,
        data: xData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          lineStyle: {
            color: "#2F457F",
            width: 3,
          },
        },
        axisLabel: {
          show: true,
          color: "#fff",
          fontSize: 14,
          interval: "auto",
          rotate:dataObj.nameRotate,
          formatter: (params) => {
            if (params) {
              let sliceParams =
                params.length > 5 ? params.slice(0, 5) + "..." : params;
              return sliceParams;
            }
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        gridIndex: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        min: min,
        // max: dataObj.max,
        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
    ],
    series: [
      {
        name: dataObj.seriesName,
        type: "line",

        showAllSymbol: false,
        symbolSize: 0,
        smooth: true,
        lineStyle: {
          normal: {
            width: 2,
            color: lineColor, // 线条颜色
          },
          borderColor: "rgba(0,0,0,.4)",
        },
        itemStyle: {
          color: lineColor,
          borderColor: "#646ace",
          borderWidth: 2,
        },
        tooltip: {
          show: true,
        },
        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: areaColor,
            shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
            shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
        data: yData,
        zlevel: 11,
      },
      {
        name: "总量",
        type: "bar",
        barWidth: 30,
        tooltip: {
          show: false,
        },
        barGap: "-100%",
        data: maxData,
        itemStyle: {
          normal: {
            color: "rgba(47,69,127,0.62)",
          },
        },
        zlevel: 9,
      },
    ],
  };

  oneBar.setOption(option);
  return oneBar;
}
export function radarCharts(element, data) {
  let radarchart = echarts.init(element);
  var legendData = ["车辆数", "设计车位"]; //图例
  var indicator = [
    {
      text: "小型车",
      max: 6000,
    },
    {
      text: "中型车",
      max: 5000,
    },
    {
      text: "大型车",
      max: 5000,
    },
    {
      text: "货车",
      max: 5000,
      //  axisLabel: {show: true, textStyle: {fontSize: 18, color: '#333'}}
    },
    {
      text: "特种车",
      max: 5000,
    },
    {
      text: "贵宾车",
      max: 5000,
    },
  ];
  var dataArr = [
    {
      value: [4300, 4700, 3600, 3900, 3800, 4200],
      name: legendData[0],
      itemStyle: {
        normal: {
          lineStyle: {
            color: "#4A99FF",
            // shadowColor: '#4A99FF',
            // shadowBlur: 10,
          },
          shadowColor: "#4A99FF",
          shadowBlur: 10,
        },
      },
      areaStyle: {
        normal: {
          // 单项区域填充样式
          color: {
            type: "linear",
            x: 0, //右
            y: 0, //下
            x2: 1, //左
            y2: 1, //上
            colorStops: [
              {
                offset: 0,
                color: "#4A99FF",
              },
              {
                offset: 0.5,
                color: "rgba(0,0,0,0)",
              },
              {
                offset: 1,
                color: "#4A99FF",
              },
            ],
            globalCoord: false,
          },
          opacity: 1, // 区域透明度
        },
      },
    },
    {
      value: [3200, 3000, 3400, 2000, 3900, 2000],
      name: legendData[1],
      itemStyle: {
        normal: {
          lineStyle: {
            color: "#4BFFFC",
            // shadowColor: '#4BFFFC',
            // shadowBlur: 10,
          },
          shadowColor: "#4BFFFC",
          shadowBlur: 10,
        },
      },
      areaStyle: {
        normal: {
          // 单项区域填充样式
          color: {
            type: "linear",
            x: 0, //右
            y: 0, //下
            x2: 1, //左
            y2: 1, //上
            colorStops: [
              {
                offset: 0,
                color: "#4BFFFC",
              },
              {
                offset: 0.5,
                color: "rgba(0,0,0,0)",
              },
              {
                offset: 1,
                color: "#4BFFFC",
              },
            ],
            globalCoord: false,
          },
          opacity: 1, // 区域透明度
        },
      },
    },
  ];
  var colorArr = ["#4A99FF", "#4BFFFC"]; //颜色
  let option = {
    // backgroundColor: "#101736",
    color: colorArr,
    legend: {
      orient: "vertical",
      icon: "circle", //图例形状
      data: legendData,
      bottom: 10,
      right: 10,
      itemWidth: 14, // 图例标记的图形宽度。[ default: 25 ]
      itemHeight: 14, // 图例标记的图形高度。[ default: 14 ]
      itemGap: 10, // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
      textStyle: {
        fontSize: 14,
        color: "#00E4FF",
      },
    },
    radar: {
      // shape: 'circle',
      name: {
        textStyle: {
          color: "#fff",
          fontSize: 12,
        },
      },
      indicator: indicator,
      splitArea: {
        // 坐标轴在 grid 区域中的分隔区域，默认不显示。
        show: true,
        areaStyle: {
          // 分隔区域的样式设置。
          color: ["rgba(255,255,255,0)", "rgba(255,255,255,0)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
        },
      },
      axisLine: {
        //指向外圈文本的分隔线样式
        lineStyle: {
          color: "#153269",
        },
      },
      splitLine: {
        lineStyle: {
          color: "#113865", // 分隔线颜色
          width: 1, // 分隔线线宽
        },
      },
    },
    series: [
      {
        type: "radar",
        symbolSize: 8,
        // symbol: 'angle',
        data: dataArr,
      },
    ],
  };
  radarchart.setOption(option);
  return radarchart;
}
export function hBarCharts(element, datas, color1, color2, seriesName) {
  let hBarchart = echarts.init(element);

  let maxArr = new Array(datas.length).fill(getMax(datas));
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: -80,
      right: 0,
      top: -5,
      bottom: -30,
      containLabel: true,
    },
    xAxis: {
      show: false,
      type: "value",
    },
    yAxis: [
      {
        type: "category",
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisPointer: {
          label: {
            show: true,
            margin: 30,
          },
        },
        data: datas.map((item) => item.name),
        axisLabel: {
          margin: 120,
          fontSize: 14,
          align: "left",
          color: "#D8D9DD",
          interval: 0,
          rich: {
            a1: {
              color: "#fff",
              backgroundColor: "#FF3232",
              width: 17,
              height: 15,
              align: "center",
              borderRadius: 2,
            },
            a2: {
              color: "#fff",
              backgroundColor: "#FF9800",
              width: 17,
              height: 15,
              align: "center",
              borderRadius: 2,
            },
            a3: {
              color: "#fff",
              backgroundColor: "#FFD200",
              width: 17,
              height: 15,
              align: "center",
              borderRadius: 2,
            },
            b: {
              color: "#fff",
              backgroundColor: "#3D8FFF",
              width: 17,
              height: 15,
              align: "center",
              borderRadius: 2,
            },
          },
          formatter: function (params) {
  
            let sliceParams =
              params.length > 5 ? params.slice(0, 5) + "..." : params;
            var index = datas.map((item) => item.name).indexOf(params);
            index = index + 1;
            if (index - 1 < 3) {
              return [
                "{a" + index + "|" + index + "}" + "  " + sliceParams,
              ].join("\n");
            } else {
              return ["{b|" + index + "}" + "  " + sliceParams].join("\n");
            }
          },
        },
      },
      {
        type: "category",
        inverse: true,
        axisTick: "none",
        axisLine: "none",
        show: true,
        data: datas.map((item) => item.value),
        axisLabel: {
          show: true,
          fontSize: 14,
          color: "#f0f0f0",
          formatter: "{value}",
        },
      },
    ],
    series: [
      {
        z: 2,
        name: seriesName,
        type: "bar",
        barWidth: 10,
        zlevel: 1,
        data: datas.map((item, i) => {
          return {
            value: item.value,
            itemStyle: {
              barBorderRadius: 30,
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: color1,
                },
                {
                  offset: 1,
                  color: color2,
                },
              ]),
            },
          };
        }),
        label: {
          show: false,
          position: "right",
          color: "#333333",
          fontSize: 16,
          offset: [10, 0],
        },
      },
      {
        name: "总量",
        type: "bar",
        barWidth: 10,
        barGap: "-100%",
        tooltip: {
          show: false,
        },
        itemStyle: {
          normal: {
            barBorderRadius: 30,
            color: "rgba(42,64,118, 0.8)",
          },
        },
        data: maxArr,
      },
      {
        tooltip: {
          show: false,
        },
        // name: "XXX",
        type: "pictorialBar",
        symbol: "circle",
        symbolSize: [12, 12],
        // symbolOffset: [20, 0],
        z: 2,
        zlevel: 2,
        itemStyle: {
          normal: {
            color: "#fff",
          },
        },
        data: datas.map((item, i) => {
          return {
            value: item.value,
            symbolPosition: "end",
          };
        }),
      },
    ],
  };
  hBarchart.setOption(option,true);
  return hBarchart;
}
export function doubleLine(element, dataObj, colorObj) {
  let oneBar = echarts.init(element);
  let lineColor = colorObj ? colorObj.lineColor : "rgba(26,202,227,1)";
  let areaColor = colorObj
    ? colorObj.areaColor
    : new echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
          {
            offset: 0,
            color: "rgba(26,202,227,.3)",
          },
          {
            offset: 1,
            color: "rgba(26,202,227, 0)",
          },
        ],
        false
      );

  var xData = [],
    yData1 = [],
    yData2 = [];
  var min = 0; // 最小值的定义
  let maxData = [];
  dataObj.data1.map(function (a, b) {
    xData.push(a.name);
    if (a.value === 0) {
      yData1.push(a.value + min);
    } else {
      yData1.push(a.value);
    }
    maxData.push(dataObj.max);
    // yData.push((Math.random(0,1) * 100).toFixed(0));
  });
  dataObj.data2.map(function (a, b) {
    if (a.value === 0) {
      yData2.push(a.value + min);
    } else {
      yData2.push(a.value);
    }
  });
  let option = {
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left",
      },
      axisPointer: {
        type: "line",
        lineStyle: {
          opacity: 0,
        },
      },
      // formatter: function(prams) {
      //     if (prams[0].data === min) {
      //         return "合格率：0%"
      //     } else {
      //         return "合格率：" + prams[0].data + "%"
      //     }
      // }
    },
    legend: {
      data: ["直接访问", "背景"],
      show: false,
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "5%",
      top: "7%",
      // height: '85%',
      containLabel: true,
      z: 22,
    },
    xAxis: [
      {
        type: "category",
        gridIndex: 0,
        data: xData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          lineStyle: {
            color: "#2F457F",
            width: 3,
          },
        },
        axisLabel: {
          show: true,
          color: "#fff",
          fontSize: 14,
          interval: "auto",
          rotate:dataObj.nameRotate,
          formatter: (params) => {
            if (params) {
              let sliceParams =
                params.length > 5 ? params.slice(0, 5) + "..." : params;
              return sliceParams;
            }
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        gridIndex: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        min: min,

        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
      {
        type: "value",
        // position: "left",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        min: min,
        // max: dataObj.max,
        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
    ],
    series: [
      
      {
        name: "碳排放总量",
        type: "line",
        barWidth: "25%",
        yAxisIndex: 0,
        label: {
          //图形上的文本标签
          normal: {
            show: false,
            position: "top",
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            barBorderRadius: [4, 4, 0, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00AEFF",
              },
              {
                offset: 1,
                color: "rgba(0,174,255,0.08)",
              },
            ]),
          },
        },
        data: yData2,
        zlevel: 11,
      },
      {
        name: "碳排放强度",
        type: "line",
        yAxisIndex: 1,
        showAllSymbol: false,
        symbolSize: 0,
        smooth: true,
        label:{
          normal:{
            show:false
          }
        },
        lineStyle: {
          normal: {
            width: 2,
            color: lineColor, // 线条颜色
          },
          borderColor: "rgba(0,0,0,.4)",
        },
        itemStyle: {
          color: lineColor,
          borderColor: "#646ace",
          borderWidth: 2,
        },
        tooltip: {
          show: true,
        },
        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: areaColor,
            shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
            shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
        data: yData1,
        zlevel: 11,
      },
    ],
  };

  oneBar.setOption(option);
  return oneBar;
}
export function lineBarCharts(element, dataObj, colorObj) {
  let oneBar = echarts.init(element);
  let lineColor = colorObj ? colorObj.lineColor : "rgba(26,202,227,1)";
  let areaColor = colorObj
    ? colorObj.areaColor
    : new echarts.graphic.LinearGradient(
        0,
        0,
        0,
        1,
        [
          {
            offset: 0,
            color: "rgba(26,202,227,.3)",
          },
          {
            offset: 1,
            color: "rgba(26,202,227, 0)",
          },
        ],
        false
      );

  var xData = [],
    yData1 = [],
    yData2 = [];
  var min = 0; // 最小值的定义
  let maxData = [];
  dataObj.data1.map(function (a, b) {
    xData.push(a.name);
    if (a.value === 0) {
      yData1.push(a.value + min);
    } else {
      yData1.push(a.value);
    }
    maxData.push(dataObj.max);
    // yData.push((Math.random(0,1) * 100).toFixed(0));
  });
  dataObj.data2.map(function (a, b) {
    if (a.value === 0) {
      yData2.push(a.value + min);
    } else {
      yData2.push(a.value);
    }
  });
  let option = {
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left",
      },
      axisPointer: {
        type: "line",
        lineStyle: {
          opacity: 0,
        },
      },
      // formatter: function(prams) {
      //     if (prams[0].data === min) {
      //         return "合格率：0%"
      //     } else {
      //         return "合格率：" + prams[0].data + "%"
      //     }
      // }
    },
    legend: {
      data: ["直接访问", "背景"],
      show: false,
    },
    grid: {
      left: "0%",
      right: "0%",
      bottom: "5%",
      top: "7%",
      // height: '85%',
      containLabel: true,
      z: 22,
    },
    xAxis: [
      {
        type: "category",
        gridIndex: 0,
        data: xData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          lineStyle: {
            color: "#2F457F",
            width: 3,
          },
        },
        axisLabel: {
          show: true,
          color: "#fff",
          fontSize: 14,
          interval: 0,
          formatter: (params) => {
            if (params) {
              let sliceParams =
                params.length > 5 ? params.slice(0, 5) + "..." : params;
              return sliceParams;
            }
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        gridIndex: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        min: min,

        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
      {
        type: "value",
        position: "right",
        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        min: min,
        // max: dataObj.max,
        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
    ],
    series: [
      {
        name: "企业数量",
        type: "line",

        showAllSymbol: false,
        symbolSize: 0,
        smooth: true,
        lineStyle: {
          normal: {
            width: 2,
            color: lineColor, // 线条颜色
          },
          borderColor: "rgba(0,0,0,.4)",
        },
        itemStyle: {
          color: lineColor,
          borderColor: "#646ace",
          borderWidth: 2,
        },
        tooltip: {
          show: true,
        },
        areaStyle: {
          //区域填充样式
          normal: {
            //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: areaColor,
            shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
            shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
        data: yData1,
        zlevel: 11,
      },
      {
        name: "企业数量",
        type: "bar",
        barWidth: "25%",
        label: {
          //图形上的文本标签
          normal: {
            show: true,
            position: "top",
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            barBorderRadius: [4, 4, 0, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00AEFF",
              },
              {
                offset: 1,
                color: "rgba(0,174,255,0.08)",
              },
            ]),
          },
        },
        data: yData2,
        zlevel: 11,
      },
      {
        name: "总量",
        type: "bar",
        barWidth: "25%",

        barGap: "-100%",
        data: maxData,
        itemStyle: {
          normal: {
            color: "rgba(47,69,127,0.62)",
          },
        },
        zlevel: 9,
      },
    ],
  };

  oneBar.setOption(option);
  return oneBar;
}
export function pieCharts(element) {
  let pieCharts = echarts.init(element);
  let option = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },

    series: [
      {
        name: "访问来源",
        type: "pie",
        selectedMode: "single",
        radius: [0, "30%"],

        label: {
          normal: {
            show: false,
            position: "inner",
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 335, name: "直达", selected: true },
          { value: 679, name: "营销广告" },
          { value: 1548, name: "搜索引擎" },
        ],
      },
      {
        name: "访问来源",
        type: "pie",
        radius: ["40%", "55%"],
        itemStyle: {
          normal: {
            borderWidth: 2,
            shadowBlur: 20,
            borderColor: "rgba(0,0,0,0)",
            shadowColor: "#00ffff",
          },
        },
        label: {
          normal: {
            show: true,
            color: "rgba(255, 255, 255, 1)",
            borderColor: "rgba(0, 0, 0, 0)",
          },
        },
        data: [
          { value: 335, name: "直达" },
          { value: 310, name: "邮件营销" },
          { value: 234, name: "联盟广告" },
          { value: 135, name: "视频广告" },
          { value: 1048, name: "百度" },
          { value: 251, name: "谷歌" },
          { value: 147, name: "必应" },
          { value: 102, name: "其他" },
        ],
      },
    ],
  };
  pieCharts.setOption(option);
  return pieCharts;
}
export function doubleBar(element) {
  //------------------------------------引用请注明出处
  let doubleBar = echarts.init(element);
  var xData = ["A", "B", "C", "D", "E", "F", "G"];
  var lineData = [100, 100, 100, 100, 100, 100, 100];
  var lastYearData = [3, 20, 62, 34, 55, 65, 33];
  var thisYearData = [11, 38, 23, 39, 66, 66, 79];
  var timeLineData = [1];
  let legend = ["2017", "2018"];

  let textColor = "#fff";
  let lineColor = "rgba(255,255,255,0.2)";
  let colors = [
    {
      borderColor: "rgba(227,161,96,1)",
      start: "rgba(227,161,96,0.8)",
      end: "rgba(227,161,96,0.3)",
    },
    {
      borderColor: "rgba(0,222,255,1)",
      start: "rgba(0,222,255,0.3)",
      end: "rgba(0,222,255,0.8)",
    },
  ];
  let borderData = [];
  let scale = 2;
  borderData = xData.map((item) => {
    return scale;
  });
  var option = {
    baseOption: {
      timeline: {
        show: false,
        top: 0,
        data: [],
      },
      legend: {
        top: "5%",
        right: "5%",
        itemWidth: 20,
        itemHeight: 5,
        // itemGap: 343,
        icon: "horizontal",
        textStyle: {
          color: "#ffffff",
          fontSize: 20,
        },
        data: legend,
      },
      grid: [
        {
          show: false,
          left: "5%",
          top: "10%",
          bottom: "8%",
          containLabel: true,
          width: "37%",
        },
        {
          show: false,
          left: "52%",
          top: "10%",
          bottom: "8%",
          width: "0%",
        },
        {
          show: false,
          right: "2%",
          top: "10%",
          bottom: "8%",
          containLabel: true,
          width: "37%",
        },
      ],
      xAxis: [
        {
          type: "value",
          inverse: true,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          position: "top",
          axisLabel: {
            show: true,
            color: textColor,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: lineColor,
            },
          },
        },
        {
          gridIndex: 1,
          show: false,
        },
        {
          gridIndex: 2,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          position: "top",
          axisLabel: {
            show: true,
            color: textColor,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: lineColor,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "category",
          inverse: true,
          position: "right",
          axisLine: {
            show: true,
            lineStyle: {
              color: lineColor,
            },
          },

          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: xData,
        },
        {
          gridIndex: 1,
          type: "category",
          inverse: true,
          position: "left",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            padding: [30, 0, 0, 0],
            textStyle: {
              color: "#ffffff",
              fontSize: 20,
            },
            align: "center",
          },
          data: xData.map(function (value) {
            return {
              value: value,
              textStyle: {
                align: "center",
              },
            };
          }),
        },
        {
          gridIndex: 2,
          type: "category",
          inverse: true,
          position: "left",
          axisLine: {
            show: true,
            lineStyle: {
              color: lineColor,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: xData,
        },
      ],
      series: [],
    },
    options: [],
  };

  option.baseOption.timeline.data.push(timeLineData[0]);
  option.options.push({
    series: [
      {
        name: "2017",
        type: "bar",
        barWidth: 25,
        stack: "1",
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: colors[0].start,
              },
              {
                offset: 1,
                color: colors[0].end,
              },
            ]),
          },
        },
        label: {
          normal: {
            show: false,
          },
        },
        data: lastYearData,
        animationEasing: "elasticOut",
      },
      {
        name: "2017",
        type: "bar",
        barWidth: 25,
        stack: "1",
        itemStyle: {
          normal: {
            color: colors[0].borderColor,
          },
        },
        data: borderData,
      },
      {
        name: "2018",
        type: "bar",
        stack: "2",
        barWidth: 25,
        xAxisIndex: 2,
        yAxisIndex: 2,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: colors[1].start,
              },
              {
                offset: 1,
                color: colors[1].end,
              },
            ]),
          },
        },
        label: {
          normal: {
            show: false,
          },
        },
        data: thisYearData,
        animationEasing: "elasticOut",
      },
      {
        name: "2018",
        type: "bar",
        xAxisIndex: 2,
        yAxisIndex: 2,
        barWidth: 25,
        stack: "2",
        itemStyle: {
          normal: {
            color: colors[1].borderColor,
          },
        },
        data: borderData,
      },
    ],
  });
  doubleBar.setOption(option);
  return doubleBar;
}
export function oneBar(element, dataObj, color) {
  let oneBar = echarts.init(element);
  let barColor = color
    ? color
    : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "#00AEFF",
        },
        {
          offset: 1,
          color: "rgba(0,174,255,0.08)",
        },
      ]);
  let maxArr = new Array(dataObj.data.length).fill(getMax(dataObj.data));
  let maxArr2=new Array(dataObj.data.length).fill(getMax2(dataObj.data));
  var xData = [],
    yData = [],
    yData2=[];

  dataObj.data.map((item,index)=>{
    xData.push(item.name);
    yData.push(item.value);
   if(dataObj.seriesName2){
    yData2.push(item.value2)
   }else{
    yData2=[]
   }
  });

  let series=[]
  
 
  if(dataObj.seriesName2){
    series=[
     
      
      {
        name: dataObj.seriesName,
        type: "bar",
        barWidth: "25%",
        yAxisIndex:0,
        showBackground: true,
        backgroundStyle: {
            color: 'rgba(47,69,127,0.62)',
        },
        label: {
          //图形上的文本标签
          normal: {
            show: true,
            position: "top",
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            barBorderRadius: [4, 4, 0, 0],
            color: barColor,
            
          },
        },
        data: yData,
        zlevel: 11,
      },

      {
        name: dataObj.seriesName2,
        type: "bar",
        barWidth: "25%",
        yAxisIndex:1,
        showBackground: true,

        backgroundStyle: {

            color: 'rgba(47,69,127,0.62)',

        },
        label: {
          //图形上的文本标签
          normal: {
            show: true,
            position: "top",
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            barBorderRadius: [4, 4, 0, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#1DF6C5",
              },
              {
                offset: 1,
                color: "rgba(29,246,197,0.08)",
              },
            ]),
          },
        },
        data: yData2,
        zlevel: 11,
      },

    ]
  }else{
    series=[
      {
        yAxisIndex:0,
        name: dataObj.seriesName,
        type: "bar",
        barWidth:30,
        showBackground: true,
        backgroundStyle: {
            color: 'rgba(47,69,127,0.62)',
        },
        label: {
          //图形上的文本标签
          normal: {
            show: true,
            position: "top",
            textStyle: {
              color: "#fff",
              fontStyle: "normal",
              fontFamily: "微软雅黑",
              fontSize: 14,
            },
          },
        },
        itemStyle: {
          normal: {
            barBorderRadius: [4, 4, 0, 0],
            color: barColor,
          },
        },
        data: yData,
        zlevel: 11,
      }
]
     
  }
 

  let option = {
    tooltip: {
      trigger: "axis",
      textStyle:{
        align:'left'
      },
      axisPointer: {
        type: "line",
        lineStyle: {
          opacity: 0,
        },
      },
    },
    dataZoom: dataObj.scrollAble?{
      id: 'dataZoomX',
      type: 'slider',
      xAxisIndex: [0],
      filterMode: 'filter',
      start: 0,
      end:10
  }:null,
    grid: {
      left: "1%",
      right: "1%",
      bottom: "0%",
      top: "12%",
      // height: '85%',
      containLabel: true,
      z: 22,
    },
    xAxis: [
      {
        type: "category",
        gridIndex: 0,
        data: xData,
        axisTick: {
          alignWithLabel: true,
        },
        axisLine: {
          lineStyle: {
            color: "#2F457F",
            width: 3,
          },
        },
        axisLabel: {
          show: true,
          color: "#fff",
          fontSize: 14,
          interval: "auto",
          rotate:dataObj.nameRotate,
          formatter: (params) => {
            let sliceParams =
              params.length > 5 ? params.slice(0, 5) + "..." : params;
            return sliceParams;
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",

        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        // min: min,
        max:dataObj.max,
        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
      {
        type: "value",

        splitLine: {
          show: true,
          lineStyle: {
            color: "#2F457F",
            width: 2,
          },
        },
        axisTick: {
          show: false,
        },
        // min: min,
        // max: dataObj.max,
        axisLine: {
          lineStyle: {
            color: "#2F457F",
          },
        },
        axisLabel: {
          color: "#fff",
          formatter: "{value}",
          fontSize: 12,
        },
      },
    ],
    series: series
  };

  oneBar.setOption(option,true);
  return oneBar;
}
export function fourBar(element) {
  let fourBar = echarts.init(element);
  let option = {
    backgroundColor: "#00265f",
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      show: false,
      align: "right",
      right: 10,
      textStyle: {
        color: "#fff",
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 35,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: [
          "喀什市喀什市喀什市喀什市",
          "疏附县疏附县疏附县疏附县",
          "疏勒县疏勒县疏勒县疏勒县",
          "英吉沙县",
          "泽普县",
          "岳普湖县",
          "巴楚县巴楚县巴楚县巴楚县",
        ],
        axisLine: {
          show: true,
          lineStyle: {
            color: "#063374",
            width: 1,
            type: "solid",
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          interval: 0,
          rotate: -10,
          hideOverlap: false,
          textStyle: {
            color: "#00c7ff",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLabel: {
          formatter: "{value} %",
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#00c7ff",
            width: 1,
            type: "solid",
          },
        },
        splitLine: {
          lineStyle: {
            color: "#063374",
          },
        },
      },
    ],
    series: [
      {
        name: "接入率",
        type: "bar",
        data: [20, 50, 80, 58, 83, 68, 57],
        barWidth: 10, //柱子宽度
        barGap: 0, //柱子之间间距
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#008cff",
              },
              {
                offset: 1,
                color: "#005193",
              },
            ]),
            opacity: 1,
          },
        },
      },
      {
        name: "在线率",
        type: "bar",
        data: [50, 70, 60, 61, 75, 87, 60],
        barWidth: 10,
        barGap: 0,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00da9c",
              },
              {
                offset: 1,
                color: "#007a55",
              },
            ]),
            opacity: 1,
          },
        },
      },
      {
        name: "完好率",
        type: "bar",
        data: [70, 48, 73, 68, 53, 47, 50],
        barWidth: 10,
        barGap: 0,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#c4e300",
              },
              {
                offset: 1,
                color: "#728400",
              },
            ]),
            opacity: 1,
          },
        },
      },
    ],
  };
  fourBar.setOption(option);
  return fourBar;
}
export function hBarchart2(element) {
  let hBarchart2 = echarts.init(element);
  let option = {
    backgroundColor: "#000",

    grid: [
      {
        top: "15%",
        bottom: "15%",
        left: "15%",
        right: "15%",
      },
      {
        top: "15%",
        bottom: "15%",
        left: "15%",
        right: "15%",
      },
    ],
    xAxis: [
      {
        name: "万/m³",
        max: 500,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            color: "#10899A",
          },
        },
        axisTick: {
          show: true,
          inside: true,
          lineStyle: {
            color: "#10899A",
            fontSize: 14,
            fontFamily: "PingFangSC",
            fontWeight: 300,
          },
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (params) => {
            return "{marginTop|" + params + "}";
          },
          rich: {
            marginTop: {
              padding: [0, 0, 0, 0],
              color: "#06D3CD",
              fontSize: 14,
              fontFamily: "PingFangSC",
              fontWeight: 300,
            },
          },
        },
      },
      {
        gridIndex: 1,
        show: true,

        axisLine: {
          show: true,
          lineStyle: {
            color: "#10899A",
          },
        },
      },
    ],
    yAxis: [
      {
        data: ["天然气", "氯气"],
        axisLabel: {
          show: false,
        },
        nameTextStyle: {
          color: "#06D3CD",
          fontSize: 14,
          fontFamily: "PingFangSC",
          fontWeight: 300,
          padding: [0, 0, 8, 0],
        },
        axisLine: {
          lineStyle: {
            color: "#10899A",
          },
        },
        axisTick: {
          lineStyle: {
            color: "#10899A",
            fontSize: 14,
            fontFamily: "PingFangSC",
            fontWeight: 300,
          },
        },
      },
      {
        gridIndex: 1,
        data: ["天然气", "氯气"],
        position: "left",
        nameTextStyle: {
          color: "#06D3CD",
          padding: [0, 0, 0, 8],
          fontSize: 14,
          fontFamily: "PingFangSC",
          fontWeight: 300,
        },
        axisLabel: {
          formatter: (params) => {
            return "{marginTop|" + params + "}";
          },
          rich: {
            marginTop: {
              padding: [0, 0, 0, 0],
              color: "#06D3CD",
              fontSize: 14,
              fontFamily: "PingFangSC",
              fontWeight: 300,
            },
          },
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#10899A",
          },
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        name: "订单数量",
        type: "bar",
        itemStyle: {
          barBorderRadius: 10,
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "rgba(0,222,215,0.2)",
            },
            {
              offset: 1,
              color: "#FFFC00",
            },
          ]),
        },
        barWidth: 10,
        data: [482, 378, 446, 518, 238, 358, 312, 378, 220, 452, 302, 372],
      },
    ],
  };
  hBarchart2.setOption(option);
  return hBarchart2;
}
