<template>
  <div class="screen-footer">
    <BoxTitle
      :titleArray="['西城片区','南海子片区']"
      class="custom-box_title"
      @toggleTab="toggleTab"
    />
    <div class="legend-container">
            <div class="unit">单位：评分</div>
            
          </div>
    <div class="onebar" ref="onebar" v-chart-resize></div>
  </div>
</template>
<script setup>
import BoxTitle from "./BoxTitle.vue";
import gsap from "gsap";
import { ref, onMounted, watch } from "vue";
import { oneBar } from "../utils/echarts";
import * as echarts from "echarts/core";
import {getMax} from "@/utils/utils"
const onebar = ref(null);
const type = ref("西城片区");
let theOnebar;

const toggleTab = (val) => {
  type.value = val;
  let data = [];

  if (val === "南海子片区") {
    data = theData.value.nhpq_list.map((item) => {
      return { name: item.short_name, value: item.all_grade };
    });

    
  } else {
    data = theData.value.xcpq_list.map((item) => {
      return { name: item.short_name, value: item.all_grade};
    });
   
  }

 
  theOnebar = oneBar(onebar.value, {
    data: data,
    seriesName:'整体评分',
    nameRotate:35
  });
 
};
const props = defineProps({
  data: Object,
});
const theData = ref(null);
watch(
  () => props.data,
  (value, oldValue) => {
    initCharts(value);
  }
);
const initCharts = (val) => {
  theData.value = val;
  
  let data = theData.value.xcpq_list.map((item) => {
      return { name: item.short_name, value: item.all_grade };
    });
  theOnebar = oneBar(onebar.value, {
    data: data,
    seriesName: '整体评分',
    nameRotate:35
  });
};
onMounted(() => {});
</script>
<style lang="scss" scoped>
.legend-container{
  margin-top:vh(5);
}
.screen-footer {
  position: fixed;
  bottom: 0px;
  width: vw(1920);
  padding-left: vw(525);
  height: vh(290);
  background: rgba(27, 48, 102, 0.9);
  z-index: 999;
  .onebar {
    width: 100%;
    height: vh(240);
  }

  .custom-box_title {
    margin-top: vh(2);
    width: 100%;
  }
}
</style>