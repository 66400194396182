<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="企业统计" />
      <div class="box-body box-body1">
        <div class="output-container">
          <div class="output-item green">
            <div class="output-value" v-if="theData" ><span v-toIntNumber.bar="theData.four_total">{{theData.four_total}}</span><span class="unit">家</span></div>
            <div class="output-label">Ygk&gt;90</div>
          </div>
          <div class="output-item yellow">
            <div class="output-value" v-if="theData" ><span v-toIntNumber.bar="theData.three_total">{{theData.three_total}}</span><span class="unit">家</span></div>
            <div class="output-label">80≤Ygk&lt;90</div>
          </div>
          <div class="output-item orange">
            <div class="output-value" v-if="theData" ><span v-toIntNumber.bar="theData.two_total">{{theData.two_total}}</span><span class="unit">家</span></div>
            <div class="output-label">60≤Ygk&lt;80</div>
          </div>
          <div class="output-item red">
            <div class="output-value" v-if="theData" ><span v-toIntNumber.bar="theData.one_total">{{theData.one_total}}</span><span class="unit">家</span></div>
            <div class="output-label">Ygk&lt;60</div>
          </div>
        </div>
        
        
      </div>
    </div>

    <div class="item-box bar-box">
      <BoxTitle titleText="等级分布" />
      <div class="box-body box-body3">
        <div class="oneBar-chart" ref="pie3DEl" v-chart-resize></div>
      </div>
    </div>
    
 
  </div>
</template>
<script setup>
import { ref, onMounted,watch } from "vue";
import { oneBar,hBarCharts,pie3D } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
const onebarChart=ref(null)
const hBarChartsEl = ref(null);
const hBarChartsEl2 = ref(null);
const pie3DEl=ref(null)
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 
});
const initCharts=(val)=>{
  theData.value=val
 
  const dataList=[
    {value:val.one_total,name:'Ygk<60',itemStyle:{
      color: 'rgba(204,0,0,0.6)'
    }},
    {value:val.two_total,name:'60≤Ygk<80',itemStyle:{
      color: 'rgba(242,130,17,0.6)'
    }},
    {value:val.three_total,name:'80≤Ygk<90',itemStyle:{
      color: 'rgba(255,246,11,0.6)'
    }},
    {value:val.four_total,name:'Ygk≥90',itemStyle:{
      color: 'rgba( 2,228,2,0.6)'
    }},

  ]
  
  pie3D(pie3DEl.value,dataList,'家')
  // const theOnebar = oneBar(onebarChart.value, {
  //   data:theData.value.dwzjfs_list,
  //   max: 100,
  // });

}
onMounted(() => {
  
  

});
</script>
<style lang="scss" scoped>
.pie_3d{
  height: vh(205);
}
.oneBar-chart{
  margin-top:vh(20);
  height: vh(410);
}


.box-body3{
  padding-bottom:vh(30);

}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  left:0;
  bottom:0;
  z-index: 1000;
  background: rgb(29,59,125);
  .output-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left:vw(17);
    padding-right:vw(14);
    padding-top:vh(6);
    padding-bottom:vh(37);
    .output-item {
      margin-top:vh(47);
      &.red{
        background: url(../assets/image/level_red_bg.png) no-repeat;
        background-size: 100% 100%;
      }
      &.orange{
        background: url(../assets/image/level_orange_bg.png) no-repeat;
        background-size: 100% 100%;

      }
      &.yellow{
        background: url(../assets/image/level_yellow_bg.png) no-repeat;
        background-size: 100% 100%;

      }
      &.green{
        background: url(../assets/image/level_green_bg.png) no-repeat;
        background-size: 100% 100%;

      }
      
      width: vw(180);
      height: vh(124);
      text-align: center;
      padding-top: vh(18);
      position: relative;
      
      .output-value {
        font-size: 37px;
        line-height: 37px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #fff;
        .unit{
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #fff;
          margin-left:vw(2);
        }
      }
      .output-label {
        font-size: 18px;
        line-height: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f8feff;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: vh(19);
      }
    }
  }
  
  
}
</style>