// 定义设计稿的宽高
const designWidth = 1920;
const designHeight = 1080;

// px转vw
export const px2vw = (_px) => {
  return (_px * 100.0) / designWidth + 'vw';
};

export const px2vh = (_px) => {
  return (_px * 100.0) / designHeight + 'vh';
};

export const px2font = (_px) => {
  return (_px * 100.0) / designWidth + 'vw';
};
export function getNumberYears(currentYear,number){
 

  let yearArray=[]
  for(let i=0;i<number;i++){
    yearArray.push(parseInt(currentYear)-i)
  }
  return yearArray
}
//转换请求参数
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
      const value = params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
          if (typeof value === 'object') {
              for (const key of Object.keys(value)) {
                  let params = propName + '[' + key + ']';
                  var subPart = encodeURIComponent(params) + "=";
                  result += subPart + encodeURIComponent(value[key]) + "&";
              }
          } else {
              result += part + encodeURIComponent(value) + "&";
         }
      }
  }
  return result
}
export function getMax(data){
  let oneBarValues=data.map((item)=>{
    return item.value
  })
  
  let max= Math.max(...oneBarValues)
  if(max){
    return max
  }else{
    return 100
  }
  
}
export function getMax2(data){
  let oneBarValues=data.map((item)=>{
    return item.value2
  })
  
  let max= Math.max(...oneBarValues)
  if(max){
    return max
  }else{
    return 100
  }
  
}