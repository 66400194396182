<template>
  <div class="right-aside">
    <!-- <div class="item-box bar-box">
      <BoxTitle titleText="工业固废利用率" unit="单位:%" />
      <div class="box-body box-body2">
        <div class="onebar-chart" ref="onebarChart" v-chart-resize></div>
      </div>
    </div> -->

    <div class="item-box bar-box">
      <BoxTitle titleText="新鲜水使用量排行" unit="单位:t"/>
      <div class="box-body box-body3">
        <div class="chart-outer hbar-chart-outer">
          <div class="hBar-chart" ref="hBarChartsEl" v-chart-resize></div>
        </div>
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="循环水使用量排行" unit="单位:t"/>
      <div class="box-body box-body3">
        <div class="chart-outer  hbar-chart-outer">
          <div class="hBar-chart" ref="hBarChartsEl2" v-chart-resize></div>
        </div>
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="中水回用率" unit="单位:%" />
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="lineBar-chart" ref="linebar" v-chart-resize></div>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from "vue";
import { oneBar, hBarCharts, lineCharts } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";

const onebarChart2 = ref(null);
import * as echarts from "echarts/core";
import { getMax } from "@/utils/utils";
const props = defineProps({
  data: Object,
});
const theData = ref(null);
watch(
  () => props.data,
  (value, oldValue) => {
    initCharts(value);
  }
);
const hBarChartsEl = ref(null);
const hBarChartsEl2 = ref(null);
const initCharts = (val) => {
  theData.value = val;
  
  lineCharts(linebar.value, {
    data: theData.value.gyysly_list,
    max: getMax(theData.value.gyysly_list),
    seriesName:'中水回用率',
    yMax:100,
    nameRotate:30
  });
  const theHbarCharts = hBarCharts(hBarChartsEl.value,theData.value.xxs_list,"rgba(29,224,246,0)","rgba(29,224,246,1)",'新鲜水使用量');
  const theHbarCharts2 = hBarCharts(hBarChartsEl2.value,theData.value.xhs_list,"rgba(27,220,181,0)","rgba(27,220,181,1)",'循环水使用量');
};
const linebar = ref(null);


onMounted(() => {
  // const theHbarCharts = hBarCharts(hBarChartsEl.value);
});
</script>
<style lang="scss" scoped>
.hbar-chart-outer{
  height: vh(220);
  
}
.hBar-chart{

  height: vh(1200);
}

.lineBar-chart {
  height: vh(280);
  width:vw(1800);
}
.box-body1 {
  // height: vh(216);
  padding-bottom: vh(21);
}
.box-body2 {
  // height: vh(306);
  padding-top: vh(25);
  padding-bottom: vh(32);
  .onebar-chart {
    height: vh(200);
  }
}
.box-body3 {
  // height: vh(226);
  padding-top: vh(10);
  padding-bottom: vh(10);
}
.right-aside {
  padding-top: vh(90);
  padding-left: vw(23);
  padding-right: vw(18);
  position: fixed;
  width: vw(469);
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1004;
  background: rgb(29, 59, 125);
  .output-container {
    margin-top: vh(17);
    display: flex;
    .output-left {
      margin-top: vh(37);
      background: url(../assets/image/chanzhi_bg.png) no-repeat;
      background-size: 100% 100%;
      width: vw(180);
      height: vh(124);
      text-align: center;
      padding-top: vh(18);
      position: relative;
      .output-value {
        font-size: 37px;
        line-height: 37px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #33eeff;
      }
      .output-label {
        font-size: 18px;
        line-height: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f8feff;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: vh(19);
      }
    }
    .output-right {
      margin-left: vw(46);
      .output-item {
        width: vw(159);
        padding-bottom: vh(10);
        // height: vh(81);
        text-align: center;
        padding-top: vh(11);
        position: relative;
        &:nth-child(1) {
          background: linear-gradient(
            0deg,
            rgba(47, 200, 238, 0.3) 0%,
            rgba(47, 200, 238, 0) 100%
          );

          border-radius: 4px;
          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: vw(26);
            height: vh(2);
            background: #33eeff;
          }
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #33eeff;
            line-height: 30px;

            background: linear-gradient(
              180deg,
              #4cbaff 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &:nth-child(2) {
          margin-top: vh(20);
          background: linear-gradient(
            0deg,
            rgba(237, 169, 36, 0.3) 0%,
            rgba(237, 169, 36, 0) 100%
          );

          border-radius: 4px;
          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: vw(26);
            height: vh(2);
            background: #ff8924;
          }
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff8924;
            line-height: 30px;

            background: linear-gradient(
              180deg,
              #d2a51c 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .item-name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 14px;
          margin-bottom: vh(17);
        }
        .item-value {
        }
      }
    }
  }
}
</style>