<template>
  <div class="screen-footer">
    <div class="footer-left">
      <BoxTitle
        :titleArray="['废气排放', '废水排放', '固废产生处置利用情况']"
        class="custom-box_title"
        @toggleTab="toggleTab"
      />
      <div class="media-container" v-if="type === '废气排放' && theData">
        <div
          class="media-item gas"
          v-for="(item, index) in theData.fqpfw_data"
          :key="index"
        >
          <div class="media-img">
            <img
              src="../assets/image/icongreen.png"
              alt=""
              v-if="index % 2 == 0"
            />
            <img src="../assets/image/iconblue.png" alt="" v-else />
          </div>
          <div class="media-info">
            <div class="info-label">{{ item.name }}</div>
            <div class="info-body">
              <span class="info-value"
                ><span v-toFloat3Number.bar="item.value">{{ item.value }}</span
                >{{ item.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="media-container" v-if="type === '废水排放' && theData">
        <div
          class="media-item water"
          v-for="(item, index) in theData.fspfw_data"
          :key="index"
        >
          <div class="media-img">
            <img
              src="../assets/image/icongreen.png"
              alt=""
              v-if="index % 2 == 0"
            />
            <img src="../assets/image/iconblue.png" alt="" v-else />
          </div>
          <div class="media-info">
            <div class="info-label">{{ item.name }}</div>
            <div class="info-body">
              <span class="info-value"
                ><span v-toFloat3Number.bar="item.value">{{ item.value }}</span
                >{{ item.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="media-container"
        v-if="type === '固废产生处置利用情况' && theData"
      >
        <div
          class="media-item water"
          v-for="(item, index) in theData.gfpfw_data"
          :key="index"
        >
          <div class="media-img">
            <img
              src="../assets/image/icongreen.png"
              alt=""
              v-if="index % 2 == 0"
            />
            <img src="../assets/image/iconblue.png" alt="" v-else />
          </div>
          <div class="media-info">
            <div class="info-label">{{ item.name }}</div>
            <div class="info-body">
              <span class="info-value"
                ><span v-toFloat3Number.bar="item.value">{{ item.value }}</span
                >{{ item.unit }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-right">
      <BoxTitle
        :titleArray="['行业分析']"
        class="custom-box_title"      
      />
      <div class="tab-container" v-if="theData">
        <span class="tab-item" :class="{active:analyseType===item.name}" v-for="item in theData.hyfx_data" @click="setAnalyse(item)">{{ item.name }}</span>

      </div>
      <div class="select-container">
        <el-select v-model="analyse" placeholder="请选择" @change="analyseChnage" size="small" class="select">
        <el-option :value="item.code" v-for="item in analyseSub" :label="item.name"></el-option>
      </el-select>
      </div>
      <div class="unit-container" v-if="currentAnalyse">
        <div class="unit-item" style="color: #00aeff">{{ currentAnalyse.unit }}</div>
        <div class="unit-item" style="color: #1df6c5">{{ currentAnalyse.unit2 }}</div>
      </div>
      <div class="echarts-container" ref="onebarChart" v-chart-resize></div>
   
    </div>
  </div>
</template>
<script setup>
import BoxTitle from "./BoxTitle.vue";
import gsap from "gsap";
import { ref, onMounted, watch } from "vue";
import { oneBar } from "../utils/echarts";
import * as echarts from "echarts/core";
import {getDpwrwCode} from '@/api/data'

const linecharts = ref(null);
const onebarChart = ref(null);
const type = ref("废气排放");

const toggleTab = (val) => {
  type.value = val;
};
//分析类型
const analyseType=ref('')
//分析因子
const analyse=ref('')
//当前分析因子
let currentAnalyse=ref(null)
let analyseSub=ref([])
const setAnalyse=(item)=>{
  analyseSub.value=item.childs
  analyseType.value=item.name
  analyse.value=analyseSub.value[0].code
  setCharts(analyse.value,props.year)
}

const props = defineProps({
  data: Object,
  year:String
});
const theData = ref(null);
watch(()=>props.year,(value,oldValue)=>{
  setCharts(analyse.value,props.year)
})
watch(
  () => props.data,
  (value, oldValue) => {
    initCharts(value);
  }
);
const analyseChnage=(value)=>{
  currentAnalyse.value=analyseSub.value.find((item)=>{
    return item.code===analyse.value
  })

  getDpwrwCode({code:value,year:props.year})
  setCharts(value,props.year)
}
const initCharts = (val) => {
  theData.value = val;
  
  analyseSub.value=val.hyfx_data[0].childs
  analyseType.value=val.hyfx_data[0].name
  analyse.value=val.hyfx_data[0].childs[0].code
  currentAnalyse.value=analyseSub.value.find((item)=>{
    return item.code===analyse.value
  })

  setCharts(analyse.value,props.year)

};
const setCharts=(code,year)=>{
  getDpwrwCode({code:analyse.value,year:props.year}).then((res)=>{
    res.data.map((item)=>{
      item.value=item.value1
    })
  oneBar(onebarChart.value, {
      data: res.data,
      seriesName: '总量',
      seriesName2: '强度',
   
    });
  })
}
onMounted(() => {});
</script>
<style lang="scss" scoped>
.unit-container{
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin:vh(5) 0;
}
.select-container{
  text-align: right;
  
  .select{
  margin-top:vh(5);
  width:vw(150);
  :deep .el-input__wrapper{
    box-shadow:0 0 0 1px rgb(0, 174, 255) inset!important;
    }
    :deep .el-input__inner{
      color:rgb(0, 174, 255);
    }
    :deep .el-select__icon{
      color:rgb(0, 174, 255);

    }
}
}

.echarts-container{
  height: vh(200);
}
.media-container {
  padding-bottom: vh(33);
  margin-top: vh(31);
  display: flex;
  flex-wrap: wrap;
  height: vh(290);
  overflow: hidden;
  padding-bottom: vh(50);
  &:hover{
  overflow: auto;

  }
  .media-item {
    margin-right: vw(10);
    &.gas {
      .info-value {
        font-size: 28px;
        line-height: vh(28);
        font-family: PangMenZhengDao-3;
        font-weight: 400;
        color: #00ffcc;
      }
    }
    &.water {
      .info-value {
        font-size: 28px;
        line-height: vh(28);
        font-family: PangMenZhengDao-3;
        font-weight: 400;
        color: #00ccff;
      }
    }
    margin-bottom: vh(20);
    display: flex;
    flex-wrap: wrap;
    .media-img {
      img {
        width: vw(54);
        // height: vh(55);
        vertical-align: top;
      }
    }
    .media-info {
      // width:vw(135);
      padding-left: vw(11);
      padding-top: vh(7);
      // margin-left:vw(8);
      .info-label {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 16px;
      }
      .info-body {
        margin-top: vh(12);

        .info-value {
          display: inline-block;
          min-width: vw(155);
          font-size: 20px;
          line-height: vh(20);
          font-weight: bold;
        }
      }
    }
  }
}
.screen-footer {
  position: fixed;
  top:vh(752);
  bottom:0;
  width: vw(1920);
  // height: vh(320);
  background: rgba(27, 48, 102, 0.9);
  z-index: 1002;
  padding-left: vw(18);
  padding-right: vw(500);
  display: flex;
  .footer-right{
    margin-left:vw(10);
    flex:1;
    position:relative;
    .tab-container{
      position: absolute;
      top:0;
      right:0;
      height: 42px;
      display: flex;
      align-items: center;
      padding-right:vw(10);
      font-family: Microsoft YaHei;
      .tab-item{
        color:#fff;
        &.active{
          color:rgb(110, 226, 255);
        }
        
        cursor: pointer;
        display: inline-block;
        line-height: 16px;
        padding:0 10px;
        &:nth-child(2){
          border-left:1px solid rgb(110, 226, 255);
          border-right:1px solid rgb(110, 226, 255);
          
        }
       

      }
    }
  }
  .footer-left {
    width: vw(600);
  }
  .legend-container {
    display: flex;
    justify-content: space-between;
    .unit {
      font-size: 13px;
      line-height: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .legend-block {
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 13px;
      &::before {
        display: inline-block;
        content: "";
        width: vw(12);
        height: vh(12);
        background: #ff8924;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: vw(5);
      }
    }
  }
  .charts-container {
    margin-top: vh(74);
    display: inline-block;
  }
  .custom-box_title {
    margin-top: vh(2);
    width: 100%;
  }
  .line-charts {
    height: vh(220);
    width: vw(938);
  }
}
</style>