<template>
    <div class="box-title">
        <div class="top-bar-container">
            <div class="left-bar"></div>
            <div class="right-bar"></div>
        </div>
        <!-- <img src="../assets/image/boxtitle.png" alt=""> -->
        <img src="../assets/image/title_arrow.png" alt="" class="title_arrow">
        <div class="title-item" v-for="(item,index) in theArray" :key="index" @click="toggleTab(item)">  
            <img src="../assets/image/titlelight2.png" alt="" class="title_light" v-if="tabVal===item">          
            <span class="title-text">{{ item }}</span>

        </div>
       
        <span class="title-unit">{{ unit }}</span>
    </div>
</template>
<script setup>
import {ref,onMounted} from 'vue'
const theArray=ref([])
const props=defineProps({
    titleText:String,
    titleArray:Array,
    unit:String
})
const emit = defineEmits(['toggleTab'])

const tabVal=ref('')
const toggleTab=(tab)=>{
    tabVal.value=tab
    emit('toggleTab',tab)
    
}
onMounted(()=>{
    if(props.titleText){
        theArray.value.push(props.titleText)
    }
    if(props.titleArray){
        theArray.value=props.titleArray
    }
    tabVal.value=theArray.value[0]
})
</script>
<style lang="scss" scoped>
.box-title{
    
    .title_arrow{
   position:absolute;
    top:50%;
    transform: translateY(-50%);
    left:vw(5);
    }
    position:relative;
    width:100%;
    height: 42px;
    line-height:42px;
    background-color: rgba(32,81,151,0.7);
    padding-left:vw(26);
    display: flex;
   
    align-items: center;
    padding-right:vw(17);
    .title-unit{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #A3C6FF;
        position:absolute;
        right:vw(15);
    }
    .title-item{
        position:relative;
        // margin-right:vw(40);
        padding:0 vw(20);
        height: 40px;
        background: linear-gradient(90deg, rgba(20,44,78,0) 0%, rgba(46,122,255,0.2) 50%, rgba(20,44,78,0) 100%);
        cursor: pointer;
        .title_light{
            position:absolute;
          
           left:50%;
            transform: translateX(-50%);
            bottom:-16px;
    
        }
    }
    .title-text{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;

        background: linear-gradient(0deg, #79C5FF 0%, #FFFFFF 59.8388671875%, #FFFFFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .top-bar-container{
        display: flex;
        justify-content: space-between;
        background: #142C4E;
        position: absolute;
        top:vh(-2);
        left:0;
        width:100%;
        div{
            
            background: #2E90FF;
            width:vw(16);
            height: vh(2);
        }
    }
img{
 
    
}
}
</style>