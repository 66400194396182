import request from '@/utils/request'
// 园区概况
export function dpIndex(query) {
    return request({
      url: '/Dpindex/index',
      method: 'get',
      params: query
    })
  }
  export function Dpnyxh(query) {
    return request({
      url: '/Dpnyxh/index',
      method: 'get',
      params: query
    })
  }
export function getCompanyList(query){
  return request({
    url: '/Company/getList',
    method: 'get',
    params: query
  })
}
export function getDpnyxh(query){
  return request({
    url: '/Dpnyxh/index',
    method: 'get',
    params: query
  })
}
export function getDpzyxh(query){
  return request({
    url: '/Dpzyxh/index',
    method: 'get',
    params: query
  })
}
//污染物排放
export function getDpwrw(query){
  return request({
    url: '/Dpwrw/index',
    method: 'get',
    params: query
  })
}
//炭排放
export function getDptpf(query){
  return request({
    url: '/Dptpf/index',
    method: 'get',
    params: query
  })
}
//获取企业菜单信息
export function getMenu(query){
  return request({
    url: '/Company/getMenu',
    method: 'get',
    params: query
  })
}
//因子趋势数据
export function getCodeData(query){
  return request({
    url:'/Company/getCodeData',
    method:'get',
    params:query
  })
}
//整体水平
export function getDpztsp(query){
  return request({
    url:'/Dpztsp/index',
    method:'get',
    params:query
  })
}
//获取园区边界
export function getAreaList(query){
  return request({
    url:'/Company/getAreaList',
    method:'get',
    params:query
  })
}
export function login(query){
  return request({
    url:'/Company/login',
    method:'get',
    params:query
  })
}
//获取污染物数据
export function getDpwrwCode(query){
  return request({
    url:'/Dpwrw/getCodeData',
    method:'get',
    params:query
  })
}
