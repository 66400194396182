<template>
  <div class="header-container">
    <div class="screen-header">
      <div class="header-left-aside">
        <div class="header-name">
          <img class="title-bg" src="../assets/image/titlebg.png" alt="" />
          <img
            class="title-light"
            src="../assets/image/titlelight.png"
            alt=""
          />
        </div>
        <div class="header-nav">
          <div
            class="nav-item"
            @click="toggleNav('园区概况')"
            :class="{ active: currentNav === '园区概况' }"
          >
            园区概况
          </div>
          <div
            class="nav-item"
            @click="toggleNav('整体水平')"
            :class="{ active: currentNav === '整体水平' }"
          >
            整体水平
          </div>
          <div
            class="nav-item"
            @click="toggleNav('能源消耗')"
            :class="{ active: currentNav === '能源消耗' }"
          >
            能源消耗
          </div>
          <div
            class="nav-item"
            @click="toggleNav('资源消耗')"
            :class="{ active: currentNav === '资源消耗' }"
          >
            资源消耗
          </div>
          <div
            class="nav-item"
            @click="toggleNav('污染物排放')"
            :class="{ active: currentNav === '污染物排放' }"
          >
            污染物排放
          </div>
          <div
            class="nav-item"
            @click="toggleNav('碳排放')"
            :class="{ active: currentNav === '碳排放' }"
          >
            碳排放
          </div>
        </div>
      </div>
      <div class="header-right-aside">
        <div class="right-top">
          <span class="blue-text">曲靖经开区管委会</span>，欢迎你
          
          <el-icon class="logout" :size="20" color="rgb(74, 164, 225)" @click="handleLogout"><SwitchButton /></el-icon> 
        </div>
        <div class="right-bottom" v-if="year">
          <span class="time"
            >{{ hours
            }}<span class="minutesState" :class="{ showState: minutesState }"
              >:</span
            >{{ minutes < 10 ? "0" + minutes : minutes }}</span
          ><span class="week">{{ setWeek(day) }}</span>
          <span class="date" >{{ year }}/{{ month }}/{{ date }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import eventHub from "@/utils/eventHub";
import {useRouter} from 'vue-router'
const currentNav = ref("园区概况");
//分
const minutes = ref("");
//时
const hours = ref("");
//星期
const day = ref("");
//年
const year = ref("");
//月
const month = ref("");
//日
const date = ref("");
const minutesState = ref(true);
const router=useRouter()
const handleLogout=()=>{
  router.push({path:'/login'})
}
setInterval(() => {
  const theDate = new Date();
  minutes.value = theDate.getMinutes();
  hours.value = theDate.getHours();
  day.value = theDate.getDay();
  year.value = theDate.getFullYear();
  month.value = theDate.getMonth() + 1;
  date.value = theDate.getDate();
  minutesState.value = minutesState.value ? false : true;
}, 1000);
const setWeek = (week) => {
  switch (week) {
    case 0:
      return "星期天";
    case 1:
      return "星期一";
    case 2:
      return "星期二";
    case 3:
      return "星期三";
    case 4:
      return "星期四";
    case 5:
      return "星期五";
    case 6:
      return "星期六";
  }
};
const toggleNav = (nav) => {
  currentNav.value = nav;
  eventHub.emit("toggleNav", currentNav);
};
</script>
<style scoped lang="scss">
.logout{
  vertical-align: sub;
  cursor: pointer;
}
@media screen and (max-width:2100px){
  .screen-header{
    .header-right-aside{
      // padding-right:vw(15)!important;
    }
    .header-left-aside{
      .header-nav{
        .nav-item{
          width: vw(160)!important;
          height: vh(50)!important;
          font-size: 14px!important;
        }
      }
    }
  }

}
@media screen and (max-width: 1920px) {

  .screen-header {
    .header-left-aside{
      .header-name{
        height: vh(59)!important;
      width: vw(660)!important;
        .title-bg{

        }
        .title-light{

        }
      }
      .header-nav{
        .nav-item{
          width: vw(170)!important;
          height: vh(50)!important;
          font-size: 14px!important;
        }
      }
    }
    .header-right-aside {
      position: relative;
      .right-top {
       
      }
      .right-bottom{
        .time {
          font-size: 18px !important;
        }
        .week {
          font-size: 16px !important;
        }
      }
    }
  }
}
.minutesState {
  color: transparent;
  &.showState {
    color: #ffffff;
  }
}
.header-container {
  position: fixed;
  top: 0;
  width: vw(1920);
  z-index: 1005;
  background: rgb(29, 59, 125);
  padding-bottom: vh(23);
}
.screen-header {
  background: rgb(23, 55, 122);
  display: flex;
  justify-content: space-between;
  .header-right-aside {
    position: absolute;
    right:0;
    top:0;
    padding-top: vh(10);
    padding-right: vw(10);
    .right-top {
    
      .blue-text{
        color:rgb(110,226,255);
        font-weight: bold;
      }
      line-height: 17px;
    }
    .right-bottom {
      margin-top: vh(7);
      display: flex;
      align-items: center;
      .time {
        font-size: 24px;
        font-family: Montserrat;
        font-weight: bold;
        color: #ffffff;
      }
      .week {
        margin-left: vw(7);
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .date {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.5;
        margin-left:10px;
      }
    }
  }
  .header-left-aside {
    display: flex;

    .header-nav {
      display: flex;
      padding-top: 17px;
      margin-left: vw(-25);
      .nav-item {
        cursor: pointer;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        width: vw(175);
        height: vh(51);
        padding-top: vh(10);
        padding-left: vh(15);
        background: url(../assets/image/navbluebg.png) no-repeat center center;
        background-size: contain;
        text-align: center;
        &.active {
          background: url(../assets/image/navorangebg.png) no-repeat center
            center;
          background-size: contain;
        }
      }
    }
    .header-name {
      height: vh(69);
      width: vw(680);
      position: relative;
      .title-bg {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .title-light {
        position: absolute;
        bottom: -51px;
        left: 67px;
      }
    }
    // height: ;
  }
}

</style>
