<template>
  <div class="login-container">
    <div class="login-box">
      <div class="logo">
        <img src="../assets/loginLogo.png" alt="">
      </div>
      <div class="plateform-cname">
        经开区管委会服务平台
      </div>
      <div class="plateform-ename">
        Economic development zone management committee service platform
      </div>
      <div class="pwd-handler">
        <div class="ipt-outer">
          <img src="../assets/lock.png" alt="">
          <div class='ipt-bar'></div>
          <input type="password" v-model="pwd" class="pwd-ipt" @keyup.enter.native="handleLogin">
        </div>
       
        <button @click="handleLogin" type="button" class="login-btn">登录</button>
      </div>
        
    </div>
  </div>
</template>

<script setup>
import {login} from '@/api/data'
import {ref} from 'vue'
import Cookies from 'js-cookie'
import {useRouter} from 'vue-router'
const pwd=ref('')
const router=useRouter()
const handleLogin=()=>{
    login({password:pwd.value}).then((res)=>{
        
       if(res.code===1){
        Cookies.set('isLogin',res.code)
        router.push({path:'/'})
       }
    })
}
</script>

<style lang="scss" scoped>
.login-container{
    width:100vw;
    height: 100vh;
    background:url(../assets/login_bg.jpg) no-repeat;
    background-size: cover;
}
.login-box{
  position: fixed;
  width:530px;
  left:50%;
  transform: translateX(-50%);
  top:167px;
  .logo{
    text-align: center;
    img{}
  }
  .plateform-cname{
    text-align: center;
    font-size: 48px;
    line-height: 48px;
    margin-top:13px;
    font-family: '微软雅黑';
    font-weight: normal;
    color: #FFFFFF;
  }
  .plateform-ename{
    text-align: center;
    margin-top:19px;
    font-size: 14px;
    line-height: 14px;
    font-family: '微软雅黑';
    font-weight: normal;
    color: #FFFFFF;
  }
  .login-box{
    text-align: center;

  }
}
.pwd-handler{
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top:76px;
  .ipt-outer{
    position: relative;
    img{
      top:11px;
      left:15px;
      width:23px;
      position: absolute;
     
    }
    
    .ipt-bar{
      left:50px;
      top:9px;
      position: absolute;
      width:1px;
      height: 27px;
      background: #1C4190;
    }
  }
}
.pwd-ipt{
  width: 356px;
height: 44px;
background: #132D70;
border: 1px solid;
border-image: linear-gradient(90deg, #1C4190, #228DD0, #1C4190) 1 1;
border-radius: 6px;
font-size: 16px;
font-family: Alibaba PuHuiTi 2.0;
font-weight: normal;
color: #7FCAFF;
outline: none;
padding-left:60px;
padding-right:10px;

}

.login-btn{
  width: 114px;
height: 44px;
background: linear-gradient(90deg, #10C4FF, #006ED9);
border-radius: 6px;
cursor: pointer;
border:none;
margin-left:22px;
}
</style>