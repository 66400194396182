<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="重点污染源稳定达标" unit="单位：家"/>
      <div class="box-body box-body1">
        <div class="pie_3d-container" >
          <div class="pie_3d" ref="pie3DEl" v-chart-resize></div>
        </div>
        
        
      </div>
    </div>

    <div class="item-box bar-box">
      <BoxTitle titleText="单位工业增加值废水排放量" unit="单位：t/万元"/>
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="oneBar-chart" ref="onebarChart" v-chart-resize></div>

        </div>
      </div>
    </div>
    
 
  </div>
</template>
<script setup>
import { ref, onMounted,watch } from "vue";
import { oneBar,hBarCharts,pie3D } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
const onebarChart=ref(null)
const hBarChartsEl = ref(null);
const hBarChartsEl2 = ref(null);
const pie3DEl=ref(null)
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 
});
const initCharts=(val)=>{
  theData.value=val
  theData.value.zdwrypfdb_list.map((item)=>{
    if(item.name==='0%~60%'){
      item.itemStyle= {
          color: 'rgba(204,0,0,0.5)',
        }
    }else if(item.name==='60%~70%'){
      item.itemStyle= {
          color: 'rgba(242,130,17,0.5)',
        }
    }else if(item.name==='70%~80%'){
      item.itemStyle= {
          color: 'rgba(255,246,11,0.5)',
        }
    }else if(item.name==='80%~90%'){
      item.itemStyle= {
          color: 'rgba( 2,228,2,0.5)'
        }
    }else if(item.name==='90%~100%'){
      item.itemStyle= {
          color: "rgba(13,82,216,0.5)",
        }
    }

  })
  pie3D(pie3DEl.value,theData.value.zdwrypfdb_list)
  const theOnebar = oneBar(onebarChart.value, {
    data:theData.value.dwzjfs_list,
    seriesName:'单位工业增加值废水排放量',
    nameRotate:30
  });

}
onMounted(() => {
  
  

});
</script>
<style lang="scss" scoped>
.pie_3d{
  height: vh(260);
}
.oneBar-chart{
  width:vw(3000);
  height: vh(280);
}


.box-body3{
  padding-bottom:vh(10);

}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  left:0;
  bottom:0;
  z-index: 1000;
  background: rgb(29,59,125);
  
  .media-container{
    padding-bottom:vh(33);
    margin-top:vh(31);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .media-item{
     
     
      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(83);
            height: vh(70);
            vertical-align: top;
          }
        }
      .media-info{

        padding-left:vw(11);
        padding-top:vh(7);
        // margin-left:vw(8);
        .info-label{
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00CCFF;

        }
        .info-body{
          margin-top:vh(20);
          
          .info-value{
            font-size: 25px;
            line-height: 25px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            color:#FF8924;
           
          }
       
        }
      }
    }
   
  }
  
}
</style>