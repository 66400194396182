import { createRouter, createWebHashHistory } from 'vue-router'
import DataScreen from '../views/DataScreen.vue'
import Login from '../views/Login'
const routes = [
  {
    path: '/',
    name: 'home',
    component: DataScreen
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
