<template>
  <div class="left-aside">

    <div class="item-box bar-box">
      <BoxTitle titleText="能源消耗" />
      <div class="box-body box-body1">
        
        <div class="media-container">
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon1.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">电力(万kwh)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.dlxh_total">{{ theData.dlxh_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon2.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">煤炭（t）</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.mtxh_total">{{ theData.mtxh_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon3.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">焦煤(t)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.jtxh_total">{{ theData.jtxh_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon4.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">天然气(万m³)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.trqxh_total">{{ theData.trqxh_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon5.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">柴油(t)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.cyxh_total">{{ theData.cyxh_total }}</span>
              </div>
            </div>
          </div>
          <div class="media-item">
            <div class="media-img">
              <img src="../assets/image/nyicon6.png" alt="">
            </div>
            <div class="media-info">
              <div class="info-label">氢气(万m³)</div>
              <div class="info-body">
                <span class="info-value" v-if="theData" v-toFloat2Number.bar="theData.qqxh_total">{{ theData.qqxh_total }}</span>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="清洁能源占比" unit="单位:%"/>
      <div class="box-body box-body2">
       <div class="chart-outer">
        <div class="onebar-chart" ref="onebarChart" v-chart-resize></div>
       </div>
        
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="可再生能源占比" unit="单位:%"/>
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="onebar-chart" ref="onebarChart2" v-chart-resize></div>
        </div>
        
      </div>
    </div>
   
  </div>
</template>
<script setup>
import { ref, onMounted,onUnmounted, watch } from "vue";
import { oneBar,hBarCharts,lineCharts } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
import * as echarts from "echarts/core";
import {getMax} from '@/utils/utils'
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 

});

const onebarChart = ref(null);
const onebarChart2 = ref(null);


const initCharts=(val)=>{
  theData.value=val
  const theOnebar = oneBar(onebarChart.value, {
    data:theData.value.qjly_list, 
    seriesName:'清洁能源',
    max:100,
    nameRotate:30
  });
  const barColor=new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "#1DF6C5",
        },
        {
          offset: 1,
          color: "rgba(29,246,197,0.08)",
        },
      ]);
  const theOnebar2 = oneBar(onebarChart2.value, {
    data: theData.value.zsly_list,
    seriesName:'可再生能源',
    max:100,
    nameRotate:30
  },barColor);
  
}

onMounted(() => {


});
</script>
<style lang="scss" scoped>


.onebar-chart{
  height: vh(180);
  width:vw(3000);
}
.box-body3,.box-body2{
  padding-bottom:vh(10);

}
.left-aside {
  padding-top:vh(90);
  padding-right:vw(23);
  padding-left:vw(18);
  position: fixed;
  width:vw(469);
  top:0;
  left:0;
  bottom:0;
  z-index: 1004;
  background: rgb(29,59,125);
  
  .media-container{
    padding-bottom:vh(33);
    margin-top:vh(31);
    display: flex;
    flex-wrap: wrap;
    .media-item{
     
      margin-bottom:vh(49);
      &:nth-child(5){
        margin-bottom:0;
      }
      &:nth-child(6){
        margin-bottom:0;
      }
      &:nth-child(2n){
        .media-info{
          width:vw(119);
          
          .info-body{
           
          }
        }
      }
      display: flex;
      flex-wrap: wrap;
      .media-img{
          img{
            width:vw(83);
            // height: vh(70);
            vertical-align: top;
          }
        }
      .media-info{
        width:vw(135);
        padding-left:vw(11);
        padding-top:vh(7);
        // margin-left:vw(8);
        .info-label{
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #00CCFF;

        }
        .info-body{
          margin-top:vh(20);
          
          .info-value{
            font-size: 25px;
            line-height: 25px;
            font-family: PangMenZhengDao;
            font-weight: bold;
            color:#FF8924;
           
          }
       
        }
      }
    }
   
  }
  
}
</style>