<template>
  <div class="loading" v-if="loading">
    <img src="../assets/icon/loading.png" alt="" />
  </div>
  <transition name="topFade">
    <ScreenHeader v-if="showAside" />
  </transition>
  <Transition name="topFade">
    <el-select
      v-model="selectValue"
      class="select-company custom-select"
      :class="{ 'right-select': nav === '整体水平' }"
      placeholder="Select"
      v-if="showAside"
      @change="selectCompany"
    >
      <el-input v-model="searchValue" @input="searchChange"></el-input>
      <el-option value="" label="全部企业" />

      <el-option
        v-for="item in searchList"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      />
    </el-select>
  </Transition>
  <Transition name="topFade">
    <el-select
      v-model="currentYear"
      class="select-year custom-select"
      placeholder="Select"
      v-if="showAside"
      @change="selectYear"
    >
      <el-option
        v-for="item in yearOptions"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
  </Transition>
  <Transition name="leftFade" key="leftFade1">
    <LeftOneAside v-if="showAside && nav === '园区概况'" :data="dpIndexData" />
  </Transition>
  <Transition name="leftFade" key="leftFade2">
    <LeftTwoAside v-if="showAside && nav === '整体水平'" :data="DpztspData" />
  </Transition>
  <Transition name="leftFade" key="leftFade3">
    <LeftThreeAside v-if="showAside && nav === '能源消耗'" :data="DpnyxhData" />
  </Transition>
  <Transition name="leftFade" key="leftFade4">
    <LeftFourAside v-if="showAside && nav === '资源消耗'" :data="DpzyxhData" />
  </Transition>
  <Transition name="leftFade" key="leftFade6">
    <LeftFiveAside v-if="showAside && nav === '污染物排放'" :data="DpwrwData" />
  </Transition>
  <Transition name="leftFade" key="leftFade6">
    <LeftSixAside v-if="showAside && nav === '碳排放'" :data="DptpfData" />
  </Transition>
  <Transition name="rightFade" key="rightFade1">
    <RighOnetAside v-if="showAside && nav === '园区概况'" :data="dpIndexData" />
  </Transition>

  <Transition name="rightFade" key="rightFade3">
    <RightThreeAside
      v-if="showAside && nav === '能源消耗'"
      :data="DpnyxhData"
    />
  </Transition>
  <Transition name="rightFade" key="rightFade4">
    <RightFourAside v-if="showAside && nav === '资源消耗'" :data="DpzyxhData" />
  </Transition>
  <Transition name="rightFade" key="rightFade5">
    <RightFiveAside
      v-if="showAside && nav === '污染物排放'"
      :data="DpwrwData"
    />
  </Transition>
  <Transition name="rightFade" key="rightFade6">
    <RightSixAside v-if="showAside && nav === '碳排放'" :data="DptpfData" />
  </Transition>

  <div class="map-container" id="map-container"></div>

  <Transition name="bottomFade">
    <BottomOneAside
      v-if="showAside && nav === '园区概况'"
      :data="dpIndexData"
    />
  </Transition>
  <Transition name="bottomFade">
    <BottomTwoAside v-if="showAside && nav === '整体水平'" :data="DpztspData" />
  </Transition>
  <Transition name="bottomFade">
    <BottomThreeAside
      v-if="showAside && nav === '能源消耗'"
      :data="DpnyxhData"
      :year="currentYear"
    />
  </Transition>
  <Transition name="bottomFade">
    <BottomFourAside
      v-if="showAside && nav === '资源消耗'"
      :data="DpzyxhData"
      :year="currentYear"
    />
  </Transition>
  <Transition name="bottomFade">
    <BottomFiveAside
      v-if="showAside && nav === '污染物排放'"
      :data="DpwrwData"
      :year="currentYear"
    />
  </Transition>
  <Transition name="bottomFade">
    <BottomSixAside v-if="showAside && nav === '碳排放'" :data="DptpfData" />
  </Transition>
  <!-- 暂时都不显示 -->
  <!-- <div class="color-container" v-if="showAside && nav === '园区概况'">
    <div class="color-item">
      <span class="green">Ygk&gt;90</span>
    </div>
    <div class="color-item">
      <span class="yellow">80≤Ygk≤90</span>
    </div>
    <div class="color-item">
      <span class="orange">60≤Ygk&lt;80</span>
    </div>
    <div class="color-item">
      <span class="red">Ygk&lt;60</span>
    </div>
   
   
   
  </div> -->
  <div class="dialog-container" v-if="dialogShow" @click="closeDialog()">
    <div class="dialog-box" @click.stop>
      <div class="dialog-title">
        <span class="title-text"
          >{{ currentDialogRegion }} · {{ currentDialogCname }}</span
        >
        <img
          src="../assets/image/close_btn.png"
          alt=""
          @click="closeDialog()"
        />
      </div>
      <div class="dialog-body">
        <div class="body-left">
          <div
            class="tab-item"
            :class="{ active: currentMenu === item.name }"
            v-for="item in menuInfoList"
            :key="item.name"
            @click="setMenu(item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="body-right">
          <div class="charts-type-container">
            <div
              class="type-item"
              :class="{ active: currentMenuChild === item.code }"
              v-for="item in currentMenuChildren"
              :key="item.code"
              @click="setMenuChild(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="unit-container">
            <div class="unit" v-if="!dialogUnit2">单位：{{ dialogUnit }}</div>
            <div class="unit" v-if="dialogUnit2" style="color: #00aeff">
              单位：{{ dialogUnit }}
            </div>
            <div class="unit" v-if="dialogUnit2" style="color: #1df6c5">
              单位：{{ dialogUnit2 }}
            </div>
          </div>
          <div class="echarts-container" ref="onebarChart" v-chart-resize></div>
        </div>
      </div>
      <div class="dialog-footer">
        <button type="button" @click="closeDialog()">关闭</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import AMapLoader from "@vuemap/amap-jsapi-loader";
import LeftOneAside from "@/components/LeftOneAside.vue";
import RighOnetAside from "@/components/RightOneAside.vue";
import LeftTwoAside from "@/components/LeftTwoAside.vue";
import RightTwoAside from "@/components/RightTwoAside.vue";
import LeftThreeAside from "@/components/LeftThreeAside.vue";
import RightThreeAside from "@/components/RightThreeAside.vue";
import LeftFourAside from "@/components/LeftFourAside.vue";
import LeftFiveAside from "@/components/LeftFiveAside.vue";
import LeftSixAside from "@/components/LeftSixAside.vue";
import RightSixAside from "@/components/RightSixAside.vue";
import RightFiveAside from "@/components/RightFiveAside.vue";

import RightFourAside from "@/components/RightFourAside.vue";

import BottomOneAside from "@/components/BottomOneAside.vue";
import BottomTwoAside from "@/components/BottomTwoAside.vue";
import ScreenHeader from "@/components/ScreenHeader.vue";
import BottomThreeAside from "@/components/BottomThreeAside.vue";
import BottomFourAside from "@/components/BottomFourAside.vue";
import BottomFiveAside from "@/components/BottomFiveAside.vue";
import BottomSixAside from "@/components/BottomSixAside.vue";
import eventHub from "@/utils/eventHub";
import testData from "@/testData/data";
import { wgs84_to_gcj02 } from "@/utils/wgs84_to_gcj02";
import { ref, onMounted, onUnmounted } from "vue";
import { getNumberYears } from "@/utils/utils";
import gsap from "gsap";
import { oneBar } from "../utils/echarts";

import {
  dpIndex,
  getCompanyList,
  getDpnyxh,
  getDpzyxh,
  getDpwrw,
  getDptpf,
  getMenu,
  getCodeData,
  getDpztsp,
  getAreaList,
} from "@/api/data";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
const router = useRouter();
const isLogin = Cookies.get("isLogin");

if (isLogin !== "1") {
  router.push({ name: "Login" });
}
//
//当前选项卡
const currentNav = ref("园区概况");
//首页数据
const dpIndexData = ref(null);
//能源消耗
const DpnyxhData = ref(null);
//资源消耗
const DpzyxhData = ref(null);
//污染物排放
const DpwrwData = ref(null);
//炭排放
const DptpfData = ref(null);
//整体水平
const DpztspData = ref(null);

//当前年份（默认显示去年）
const currentYear = ref(new Date().getFullYear() - 1);

const yearOptions = ref(getNumberYears(currentYear.value + 1, 5));
let theAMap = null;
let map = null;
//公司选项
const companies = ref([]);
//当前公司选项
const currentCompany = ref(null);
const onebarChart = ref(null);
let theOnebar = null;
//弹窗状态
const dialogShow = ref(false);

//公司标记
const markers = ref([]);
//排放口标记
const outletMarkers = ref([]);
//公司边界集合
let geojsons = [];
//排放口显示的等级
const showLevel = 13;
//加载loading
const loading = ref(true);
//是否显示两边
const showAside = ref(false);
const nav = ref("园区概况");
const companyOptions = ref([]);
const selectValue = ref("");
//排位口标记集合
let waterMarkerArray = [];
//排气口标记集合
let gasMarkerArray = [];
//弹窗菜单信息
const menuInfoList = ref([]);
//当前菜单
const currentMenu = ref("");
//当前菜单子项列表
const currentMenuChildren = ref([]);
//当前菜单子项
const currentMenuChild = ref("");
//当前弹窗企业id
const currentDialogCid = ref("");
//当前弹窗企业名称
const currentDialogCname = ref("");
//当前弹窗企业所属片区
const currentDialogRegion = ref("");
//弹窗图标单位
const dialogUnit = ref("");
const dialogUnit2 = ref("");
//园区边界集合
let areaList = [];
//企业搜索值
const searchValue = ref("");
const searchList = ref([]);
//loading定时器
let timer = setTimeout(() => {
  loading.value = false;
  clearTimeout(timer);
}, 1000);

//
const searchChange = (query) => {
  searchList.value = companyOptions.value.filter((item) => {
    // 这里是用的value选项筛选，默认是label
    return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
  });
};
//选择年份
const selectYear = (year) => {
  map.clearMap();
  getCompanyList({ year: year }).then((data) => {
    searchList.value = companyOptions.value = data.data;
    setMarker(data.data, theAMap, map, year);
    //先清空geojson
    geojsons = [];
    data.data.map((item) => {
      if (item.geojson) {
        let colorControl = "";
        if (item.all_grade >= 90) {
          colorControl = "#02e402";
        } else if (item.all_grade >= 80 && item.all_grade < 90) {
          colorControl = "#fff60b";
        } else if (item.all_grade >= 60 && item.all_grade < 80) {
          colorControl = "#f28211";
        } else if (item.all_grade < 60) {
          colorControl = "#cc0000";
        }

        let theGeojson = new theAMap.GeoJSON({
          geoJSON: JSON.parse(item.geojson),
          // 还可以自定义getMarker和getPolyline
          getPolygon: (geojson, lnglats) => {
            // 计算面积
            return new theAMap.Polygon({
              path: lnglats,

              strokeColor: "none",
              // strokeWeight: 1,
              fillColor: colorControl,
              fillOpacity: 0.8,
            });
          },
        });
        if (nav.value === "整体水平") {
          theGeojson.show();
        } else {
          theGeojson.hide();
        }

        theGeojson.cid = item.id;
        map.add(theGeojson);
        geojsons.push(theGeojson);
      }
    });
  });
  getAreaList().then((data) => {
    data.data.map((item) => {
      if (item.description) {
        let theGeojson = new theAMap.GeoJSON({
          geoJSON: JSON.parse(item.description),
          // 还可以自定义getMarker和getPolyline
          getPolygon: (geojson, lnglats) => {
            // 计算面积
            return new theAMap.Polygon({
              path: lnglats,

              strokeColor: "rgba(255, 0, 0, 1)",
              strokeWeight: 3,
              fillColor: "none",
            });
          },
        });
        // theGeojson.on('mouseover',(e)=>{
        //   console.log(e)
        // })
        map.add(theGeojson);
        areaList.push(theGeojson);
      }
    });
  });
  switch (nav.value) {
    case "园区概况":
      dpIndex({ year: currentYear.value }).then((data) => {
        dpIndexData.value = data.data;
      });
      break;
    case "整体水平":
      getDpztsp({ year: currentYear.value }).then((data) => {
        DpztspData.value = data.data;
      });
      break;
    case "能源消耗":
      getDpnyxh({ year: currentYear.value }).then((data) => {
        DpnyxhData.value = data.data;
      });
      break;
    case "资源消耗":
      getDpzyxh({ year: currentYear.value }).then((data) => {
        DpzyxhData.value = data.data;
      });
      break;
    case "污染物排放":
      getDpwrw({ year: currentYear.value }).then((data) => {
        DpwrwData.value = data.data;
      });
      break;
    case "碳排放":
      getDptpf({ year: currentYear.value }).then((data) => {
        DptpfData.value = data.data;
      });
      break;
  }
};

//获取企业菜单信息
getMenu().then((data) => {
  menuInfoList.value = data.data;
  currentMenu.value = data.data[0].name;
  currentMenuChildren.value = data.data[0].childs;
  currentMenuChild.value = data.data[0].childs[0].code;
});
const setMenu = (item) => {
  currentMenu.value = item.name;
  currentMenuChildren.value = item.childs;
  currentMenuChild.value = item.childs[0].code;
  setCodeDataCharts();
};
const setMenuChild = (item) => {
  currentMenuChild.value = item.code;
  setCodeDataCharts();
};
const setCodeDataCharts = () => {
  let name = "";
  let name2 = "";
  let item = null;
  if (currentMenuChildren) {
    item = currentMenuChildren.value.find((item) => {
      return item.code === currentMenuChild.value;
    });
  }
  name = item.name;
  name2 = item.name2;

  dialogUnit.value = item.unit;
  dialogUnit2.value = item.unit2;

  getCodeData({
    code: currentMenuChild.value,
    company_id: currentDialogCid.value,
  }).then((data) => {
    theOnebar = oneBar(onebarChart.value, {
      data: data.data,
      seriesName: name,
      seriesName2: name2,
      max: dialogUnit.value === "%" ? 100 : null,
    });
  });
};
//设置排放图标
const setPfMarkers = (AMap, waterData, cid, type) => {
  let markers = [];

  waterData.map((item, index) => {
    let content = null;

    if (type === "gas") {
      content = ` <div class="paifang-marker-container gas">
        <div class="paifang-title">${item.title}</div>
      </div>`;
    } else if (type === "water") {
      content = ` <div class="paifang-marker-container water">
        <div class="paifang-title">${item.title}</div>
      </div>`;
    }
    if (item) {
      let lnglat = wgs84_to_gcj02(item.long, item.lat);
      let marker = new AMap.Marker({
        position: new AMap.LngLat(lnglat[0], lnglat[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        content: content,
        visible: false,
        offset: new AMap.Pixel(-57.5, -28),
        extData: { id: type + cid + "_" + index, cid: cid },
      });
      markers.push(marker);
    }
  });
  return markers;
};
//设置图标信息
const setInfoBox = (item, infobox) => {
  let colorControl = "";
  if (item.all_grade >= 90) {
    colorControl = "green";
  } else if (item.all_grade >= 80 && item.all_grade < 90) {
    colorControl = "yellow";
  } else if (item.all_grade >= 60 && item.all_grade < 80) {
    colorControl = "orange";
  } else if (item.all_grade < 60) {
    colorControl = "red";
  }
  const content = `<div class="marker-container">
        ${infobox}
        <div class='icon-container'>
           <span class="maker-icon ${colorControl}"></span>
           <span class='maker-name'>${item.short_name}</span>
        </div>`;
  return content;
};
const setMarker = (companyList, AMap, map, year) => {
  companyList.map((item) => {
    if (!item.long || !item.lat) {
      return;
    }
    let lnglat = wgs84_to_gcj02(item.long, item.lat);

    const infoBoxId = "infoBox" + item.id;
    let content = `<div class="infobox" id="${infoBoxId}">
            <div class="title">${item.name}</div>
            <div class="sub-title">${year}年生产情况</div>
            <div class="data-list">
              <div class="item">
                <span class="label">生产总值</span>：<span class="value">${
                  item.cp_gyzcz
                }</span>  
              </div>
              <div class="item">
                <span class="label">工业增加值</span>：<span class="value">${
                  item.cp_gyzjz
                }</span>  
              </div>
              <div class="item">
                <span class="label">产品产量${
                  item.product_name
                    ? "（" + item.product_name + "）"
                    : item.product_name
                }</span>：<span class="value">${item.cp_cpcl}</span>  
              </div>
              <div class="item" style="display:none;">
                <span class="label">主要原料消耗量${
                  item.cp_zyyl ? "（" + item.cp_zyyl + "）" : item.cp_zyyl
                }</span>：<span class="value">${item.cp_zyylxhl}</span>  
              </div>
              <div class="item">
                <span class="label">企业清洁生产水平得分</span>：<span class="value">${
                  item.all_grade
                }</span>  
              </div>
              <div class="item">
                <span class="label">企业占地数据</span>：<span class="value">${
                  item.floor_area
                }</span>  
              </div>
            </div>
          </div>`;

    const marker = new AMap.Marker({
      position: new AMap.LngLat(lnglat[0], lnglat[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: item.lat.name,
      content: setInfoBox(item, content),
      offset: new AMap.Pixel(-57.5, -28),
      extData: {
        id: item.id,
        infoBoxId: "infoBox" + item.id,
        name: item.name,
        region: item.region,
      },
    });
    marker.on("mouseover", (e) => {
      const infoBoxId = marker.getExtData().infoBoxId;
      marker.setzIndex(15);
      document.getElementById(infoBoxId).style.display = "block";
    });
    marker.on("mouseout", (e) => {
      const infoBoxId = marker.getExtData().infoBoxId;
      document.getElementById(infoBoxId).style.display = "none";
      marker.setzIndex(12);
    });
    marker.on("click", (e) => {
      dialogShow.value = true;
      currentDialogCid.value = marker.getExtData().id;
      currentDialogCname.value = marker.getExtData().name;
      currentDialogRegion.value = marker.getExtData().region;
      setCodeDataCharts();
    });
    //排污口标记

    if (item.paiwujson) {
      let waterMarker = setPfMarkers(AMap, item.paiwujson, item.id, "water");
      waterMarkerArray.push(...waterMarker);
      map.add(waterMarker);
    }
    //排气口标记
    if (item.paiqijson) {
      let gasMarker = setPfMarkers(AMap, item.paiqijson, item.id, "gas");
      gasMarkerArray.push(...gasMarker);
      map.add(gasMarker);
    }

    //   getExtData获取自定义属性
    markers.value.push(marker);
    map.add(marker);
    companies.value.push({
      id: item.id,
      name: item.name,
      lng: lnglat[0],
      lat: lnglat[1],
      gas: item.gas,
      water: item.water,
      solid: item.solid,
    });

    // item.gas.map((item) => {
    //   const marker = new AMap.Marker({
    //     position: new AMap.LngLat(item.lng, item.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //     title: "排气口",
    //   });

    //   outletMarkers.value.push(marker);
    //   map.add(marker);
    // });
    // item.water.map((item) => {
    //   const marker = new AMap.Marker({
    //     position: new AMap.LngLat(item.lng, item.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //     title: "排水口",
    //   });
    //   outletMarkers.value.push(marker);
    //   map.add(marker);
    // });
    // item.solid.map((item) => {
    //   const marker = new AMap.Marker({
    //     position: new AMap.LngLat(item.lng, item.lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
    //     title: "固体排放",
    //   });
    //   outletMarkers.value.push(marker);
    //   map.add(marker);
    // });
    // outletMarkers.value.map((item) => {
    //   if (mapLevel < showLevel) {
    //     item.show();
    //   } else {
    //     item.hide();
    //   }
    // });
  });
};

const closeDialog = () => {
  dialogShow.value = false;
};
onMounted(() => {});
onUnmounted(() => {
  Cookies.remove("isLogin");
});

eventHub.on("toggleNav", (theNav) => {
  nav.value = theNav.value;

  switch (theNav.value) {
    case "园区概况":
      geojsons.map((item) => {
        item.hide();
      });
      dpIndex({ year: currentYear.value }).then((data) => {
        dpIndexData.value = data.data;
      });
      break;
    case "整体水平":
      geojsons.map((item) => {
        item.show();
      });

      getDpztsp({ year: currentYear.value }).then((data) => {
        DpztspData.value = data.data;
      });
      break;
    case "能源消耗":
      geojsons.map((item) => {
        item.hide();
      });
      getDpnyxh({ year: currentYear.value }).then((data) => {
        DpnyxhData.value = data.data;
      });
      break;
    case "资源消耗":
      geojsons.map((item) => {
        item.hide();
      });
      getDpzyxh({ year: currentYear.value }).then((data) => {
        DpzyxhData.value = data.data;
      });
      break;
    case "污染物排放":
      geojsons.map((item) => {
        item.hide();
      });
      getDpwrw({ year: currentYear.value }).then((data) => {
        DpwrwData.value = data.data;
      });
      break;
    case "碳排放":
      geojsons.map((item) => {
        item.hide();
      });
      getDptpf({ year: currentYear.value }).then((data) => {
        DptpfData.value = data.data;
      });
      break;
  }
});

//创建amp对象
AMapLoader.load({
  key: "d375def5a7f89cf61c6954511b7dc16e", //首次load必填
  version: "2.0",
  plugins: ["AMap.Scale", "AMap.GeoJSON"],
})
  .then((AMap) => {
    theAMap = AMap;
    showAside.value = true;
    map = new AMap.Map("map-container", {
      center: [103.656133, 25.45276],
      layers: [
        new AMap.createDefaultLayer({
          // 提示：参数值并不是API默认使用的值
          zooms: [3, 20], //可见级别
          visible: true, //是否可见
          opacity: 0.1, //透明度
          zIndex: 0, //叠加层级
        }),
        new AMap.TileLayer.Satellite(),
      ],
      zoom: 13,
      zooms: [3, 16],
    });
    //首页调用
    dpIndex({ year: currentYear.value }).then((data) => {
      dpIndexData.value = data.data;
      const center = data.data.center_zuobiao.split(",");
      map.setCenter(center);
    });

    //获取所有公司
    getCompanyList().then((data) => {
      searchList.value = companyOptions.value = data.data;
      setMarker(data.data, AMap, map, currentYear.value);
      data.data.map((item) => {
        if (item.geojson) {
          let colorControl = "";
          if (item.all_grade >= 90) {
            colorControl = "#02e402";
          } else if (item.all_grade >= 80 && item.all_grade < 90) {
            colorControl = "#fff60b";
          } else if (item.all_grade >= 60 && item.all_grade < 80) {
            colorControl = "#f28211";
          } else if (item.all_grade < 60) {
            colorControl = "#cc0000";
          }

          let theGeojson = new theAMap.GeoJSON({
            geoJSON: JSON.parse(item.geojson),
            // 还可以自定义getMarker和getPolyline
            getPolygon: (geojson, lnglats) => {
              // 计算面积
              return new theAMap.Polygon({
                path: lnglats,

                strokeColor: "none",
                // strokeWeight: 1,
                fillColor: colorControl,
                fillOpacity: 0.8,
              });
            },
          });
          theGeojson.hide();
          theGeojson.cid = item.id;
          map.add(theGeojson);
          geojsons.push(theGeojson);
        }
      });
    });
    getAreaList().then((data) => {
      data.data.map((item) => {
        if (item.description) {
          let theGeojson = new theAMap.GeoJSON({
            geoJSON: JSON.parse(item.description),
            // 还可以自定义getMarker和getPolyline
            getPolygon: (geojson, lnglats) => {
              // 计算面积
              return new theAMap.Polygon({
                path: lnglats,

                strokeColor: "rgba(255, 0, 0, 1)",
                strokeWeight: 3,
                fillColor: "none",
              });
            },
          });
          // theGeojson.on('mouseover',(e)=>{
          //   console.log(e)
          // })
          map.add(theGeojson);
          areaList.push(theGeojson);
        }
      });
    });

    // map.on('complete',()=>{
    //   loading.value=false
    // })

    let mapLevel = map.getZoom();
    map.on("zoomend", (e) => {
      mapLevel = map.getZoom();
      outletMarkers.value.map((item) => {
        if (mapLevel > showLevel) {
          item.show();
        } else {
          item.hide();
        }
      });
    });
    // map.setFitView()
  })
  .catch((e) => {
    // console.error(e);
  });

const selectCompany = (e) => {
  let id = e;
  currentCompany.value = companyOptions.value.find((item) => {
    return item.id === e;
  });

  if (e) {
    areaList.map((item) => {
      item.hide();
    });
    map.setCenter([currentCompany.value.long, currentCompany.value.lat]);
    map.setZoom(15);
    markers.value.map((item) => {
      if (e != item.getExtData().id) {
        item.hide();
      } else {
        item.show();
      }
    });
    waterMarkerArray.map((item) => {
      if (item && e != item.getExtData().cid) {
        item.hide();
      } else {
        item.show();
      }
    });
    gasMarkerArray.map((item) => {
      if (item && e != item.getExtData().cid) {
        item.hide();
      } else {
        item.show();
      }
    });
    geojsons.map((item) => {
      if (item && e != item.cid) {
        item.hide();
      } else {
        item.show();
      }
    });
  } else {
    areaList.map((item) => {
      item.show();
    });
    map.setZoom(13);
    markers.value.map((item) => {
      item.show();
    });
    waterMarkerArray.map((item) => {
      item.hide();
    });
    gasMarkerArray.map((item) => {
      item.hide();
    });
    geojsons.map((item) => {
      item.hide();
    });
  }
  // const gas = currentCompany.value.gas;
  // const water = currentCompany.value.water;
  // const solid = currentCompany.value.solid;
  // map.remove(outletMarkers.value);
};
</script>
<style lang="scss" scoped>
.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1007;
  background: rgba(0, 0, 0, 0.7);

  .dialog-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: vw(1100);
    background: rgb(49, 51, 62);
    border-radius: 4px;

    .dialog-title {
      height: vh(60);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: vw(23);
      padding-right: vw(26);
      background: rgb(61, 63, 75);

      .title-text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #cccccc;
      }

      img {
        width: vw(17);
        height: vh(17);
        cursor: pointer;
      }
    }

    .dialog-body {
      padding: vh(24) vw(24);
      display: flex;

      .body-left {
        .tab-item {
          cursor: pointer;
          margin-bottom: vh(27);
          text-align: center;
          width: vw(160);
          height: vh(52);
          line-height: vh(52);
          background: #454755;
          border-radius: 4px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;

          &.active {
            background: linear-gradient(90deg, #3479e4, #074fba);
          }
        }
      }
    }

    .body-right {
      margin-left: vw(20);

      .unit-container {
        display: flex;
        justify-content: space-between;
        width: vw(800);
      }

      .unit {
        margin-top: vh(10);
      }

      .echarts-container {
        width: vw(800);
        height: vh(400);
        margin-top: vh(10);
        background: #252732;
      }

      .charts-type-container {
        display: flex;
        width: vw(875);
        overflow: auto;

        .type-item {
          white-space: nowrap;
          color: #999999;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          cursor: pointer;
          padding-top: vh(12);
          padding-bottom: vh(20);
          padding-left: vw(21);
          padding-right: vw(21);
          border-bottom: vh(3) solid transparent;

          &.active {
            color: #fff;
            border-bottom: vh(3) solid #fff;
          }
        }
      }
    }

    .dialog-footer {
      text-align: center;
      padding-top: vh(37);
      padding-bottom: vh(42);

      button {
        width: vw(116);
        height: vh(34);
        background: #3c89ff;
        border-radius: 4px;
        border: none;
        cursor: pointer;
      }
    }
  }
}

@keyframes changeLeftShow {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes changeRightShow {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes changeright {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.color-container {
  position: fixed;
  z-index: 1005;
  right: vw(510);
  bottom: vh(189);
  // width: 125px;
  padding-top: vh(22);
  padding-bottom: vh(28);

  background: rgba(28, 69, 130, 0.9);
  border: 2px solid #2e90ff;
  border-radius: 4px;

  .color-item {
    font-size: 15px;
    line-height: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    padding-left: vw(20);
    padding-right: vw(20);
    margin-bottom: vh(20);

    &:last-child {
      margin-bottom: 0;
    }

    span::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      margin-right: vw(10);
    }

    .red::before {
      background: #cc0000;
    }

    .orange::before {
      background: #f28211;
    }

    .yellow::before {
      background: #fff60b;
    }

    .green::before {
      background: #02e402;
    }
  }
}

.select-company {
  position: fixed;
  z-index: 1005;
  top: vh(108);
  right: vw(510);
  transition: all linear 1s;
  &.right-select {
    right: vw(10);
  }
}

.select-year {
  position: fixed;
  z-index: 1005;
  top: vh(108);
  left: vw(510);
}

.leftFade-enter-active {
  animation: changeLeftShow 1s ease-in;
}

.leftFade-leave-active {
  animation: changeLeftShow 1s reverse ease-in;
}

.rightFade-enter-active {
  animation: changeRightShow 1s ease-in;
}

.rightFade-leave-active {
  animation: changeRightShow 1s reverse ease-in;
}

.topFade-enter-active,
.leftFade-leave-active {
  transition: transform 1.5s ease;
}

.topFade-enter-from,
.leftFade-leave-to {
  transform: translateY(-100%);
}

.bottomFade-enter-active,
.bottomFade-leave-active {
  transition: transform 1.5s ease;
}

.bottomFade-enter-from,
.bottomFade-leave-to {
  transform: translateY(100%);
}

.map-container {
  width: 100vw;
  height: 100vh;
}

.select-container {
  top: 90px;
  left: vw(500);
  position: fixed;
  z-index: 1005;
}

.tab-container {
  display: flex;
  font-size: 15px;

  &.left-tab-container {
    padding-left: vw(20);

    .tab-item {
      margin-right: vw(20);
    }
  }

  &.right-tab-container {
    padding-right: vw(20);

    .tab-item {
      margin-left: vw(20);
    }
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: saturate(180%) blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50px;
    height: 50px;
    animation: changeright 3s linear infinite;
  }
}
</style>