<template>
  <div class="right-aside">
    <div class="item-box  bar-box">
      <BoxTitle titleText="工业总产值" />
      <div class="box-body box-body1">
        <div class="output-container">
          <div class="output-left">
            <div class="output-value" v-if="theData"><span>{{theData.gycz_total}}</span><span class="unit">亿元</span> </div>
            <div class="output-label">工业总产值</div>
          </div>
          <div class="output-right">
            <div class="output-item">
              <div class="item-name">排污许可重点管理</div>
              <div class="value-outer">
                <img src="../assets/image/box_blue_icon.png" alt="" v-if="theData">
                <div class="item-value" v-if="theData" v-toIntNumber.bar="theData.zdgl_nums">{{ theData.zdgl_nums }}</div>
              </div>
              
            </div>
            <div class="output-item">
              <div class="item-name">排污许可简化管理</div>
              <div class="value-outer">
                <img src="../assets/image/box_orange_icon.png" alt="" v-if="theData">
                <div class="item-value" v-if="theData" v-toIntNumber.bar="theData.jhgl_nums">{{ theData.jhgl_nums }}</div>
              </div>
              
            </div>
            <div class="output-item">
              <div class="item-name">排污许可登记管理</div>
              <div class="value-outer">
                <img src="../assets/image/box_blue_icon.png" alt="" v-if="theData">
                <div class="item-value" v-if="theData" v-toIntNumber.bar="theData.djgl_nums">{{ theData.djgl_nums }}</div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="工业产值排行" unit="万元"/>
      <div class="box-body box-body2">
       <div class="chart-outer">
        <div class="onebar-chart" ref="onebarChart" v-chart-resize></div>
       </div>
       
      </div>
    </div>
    <div class="item-box bar-box">
      <BoxTitle titleText="工业增加值排行" unit="万元"/>
      <div class="box-body box-body3">
        <div class="chart-outer">
          <div class="onebar-chart" ref="onebarChart2" v-chart-resize></div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onUnmounted, watch,onMounted } from "vue";
import { oneBar, hBarCharts } from "../utils/echarts";
import BoxTitle from "./BoxTitle.vue";
const onebarChart = ref(null);
const onebarChart2 = ref(null);
const props=defineProps({
  data:Object
})
const theData=ref(null)

let theOnebar=null
let theOnebar2=null

watch(() =>props.data,(value,oldValue)=>{
  initCharts(value)
})
const initCharts=(val)=>{
  theData.value=val


  let oneBarValues=theData.value.zongliang_list.map((item)=>{
    return item.value
  })
  let oneBar2Values=theData.value.zengjia_list.map((item)=>{
    return item.value
  })

  
     theOnebar = oneBar(onebarChart.value, {
    data: theData.value.zongliang_list,
    seriesName:'工业产值',
    nameRotate:30
  });
   theOnebar2 = oneBar(onebarChart2.value, {
    data:theData.value.zengjia_list ,
    seriesName:'工业增加值',
    nameRotate:30
   
  });

}
onMounted(()=>{

})
onUnmounted(() => {
  // theOnebar.clear()
  // theOnebar2.clear()

 

});
</script>
<style lang="scss" scoped>

.box-body1 {
  // height: vh(216);
  padding-bottom:vh(21);

}
.box-body2 {
  // height: vh(306);
  padding-bottom:vh(10);
  .chart-outer{
    padding-bottom:vh(10);
 

  }
  .onebar-chart{
    width:vw(3000);
    height: vh(245);
   
  
  }
}
.box-body3 {
  // height: vh(226);
  padding-bottom:vh(10);
  .chart-outer{
    padding-bottom:vh(10);
  
  }

  .onebar-chart{
    height: vh(245);
   width:vw(3000);
  
  }
}

.right-aside {
  padding-top: vh(90);
  padding-left: vw(23);
  padding-right: vw(18);
  position: fixed;
  width: vw(469);
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1004;
  background: rgb(29, 59, 125);
  .output-container {
    margin-top:vh(17);
    display: flex;
    .output-left {
      margin-top:vh(37);
      background: url(../assets/image/chanzhi_bg.png) no-repeat;
      background-size: 100% 100%;
      width: vw(180);
      height: vh(124);
      text-align: center;
      padding-top: vh(18);
      position: relative;
      .output-value {
        font-size: 37px;
        line-height: 37px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #33eeff;
      }
      .output-label {
        font-size: 18px;
        line-height: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #f8feff;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: vh(19);
      }
      .unit{
        font-size: 14px;
        margin-left:vw(5);
      }
    }
    .output-right {
      margin-left:vw(46);
      .output-item {
        align-items: center;
        display: flex;
        width: vw(172);
        height: vh(68);

        padding-left:vw(15);
        text-align: center;

        position:relative;
        .value-outer{
          position: relative;
          img{
            position: absolute;
            bottom:0;
            left:50%;
            transform: translateX(-50%);
            z-index: -1;
            width:60px;
           
          }
        }
        &:nth-child(1) {
          background: linear-gradient(
            0deg,
            rgba(47, 200, 238, 0.3) 0%,
            rgba(47, 200, 238, 0) 100%
          );

          border-radius: 4px;
         
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #33eeff;
   
            background: linear-gradient(
              180deg,
              #4cbaff 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            
          }
        }
        &:nth-child(3) {
          background: linear-gradient(
            0deg,
            rgba(47, 200, 238, 0.3) 0%,
            rgba(47, 200, 238, 0) 100%
          );

          border-radius: 4px;
         
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #33eeff;
            
            background: linear-gradient(
              180deg,
              #4cbaff 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        &:nth-child(2) {
          
          background: linear-gradient(
            0deg,
            rgba(237, 169, 36, 0.3) 0%,
            rgba(237, 169, 36, 0) 100%
          );

          border-radius: 4px;
         
          .item-value {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff8924;
    
            background: linear-gradient(
              180deg,
              #d2a51c 0.146484375%,
              #ffffff 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .item-name {
          width:56px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin-right:vw(12);
      
        }
        .item-value {
          line-height: 34px;

          width:60px;
          height: 51px;
        }
      }
      
    }
  }
}
</style>