<template>
  <router-view />
</template>
<script setup>
  import { onMounted } from 'vue';
  import Cookies from "js-cookie";
  onMounted(()=>{
    window.addEventListener('beforeunload', e =>{
      Cookies.remove("isLogin");
    })
  })
</script>
<style lang="scss">
@font-face {
  font-family: 'PangMenZhengDao';
  src: url(./assets/font/thefont.TTF) format('truetype');
  font-weight: normal;
  font-style: normal;
}
.chart-outer{
    
  overflow: hidden;
  &:hover{
  overflow: auto;

  }
}
.left-aside{
    width:vw(500)!important;
  }
  .right-aside{
    width:vw(500)!important;

  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#app {
  width: 100vw;
  height: 100vh;
}
.paifang-marker-container{
  width:99px;
  height: 48px;
  &.gas{
    background: url(./assets/image/gas_marker_bg.png) no-repeat center center;
  }
  &.water{
    background: url(./assets/image/water_marker_bg.png) no-repeat center center;
  }

  background-size: cover;
  .paifang-title{
    text-align: center;
    padding-top:5px;
  }
}
.onebar-chart {
  height: vh(186);
}



.custom-select {
  .el-select__caret {
    svg {
      display: none;
    }

    &::after {
      display: inline-block;
      content: "";
      width: 12px;
      height: 7px;
      background: url(./assets/image/triangle.png) no-repeat;
      background-size: cover;
    }
  }

  .el-input__wrapper {
    background: linear-gradient(0deg, #203e88 0%, #395aac 100%);
    box-shadow: none;
    border-top: 2px solid #2e90ff;
    width: 137px;
    height: 40px;
    border-radius: 0;
  }

  .el-input__inner {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;

    background: linear-gradient(
      0deg,
      #79c5ff 0%,
      #ffffff 59.8388671875%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.bar-chart-legend {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;

  padding: vh(21) vw(4) vh(21) vw(6);

  .legend-left {
  }

  .legend-right {
    .legend-block {
      background: #00acfd;
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 1px;
      vertical-align: middle;
    }

    .legend-text {
      margin-left: vw(9);
    }
  }
}

.item-box {
  &.bar-box {
    margin-bottom: vh(32);
    margin-top:5px;
  }

  .box-body {
    position: relative;
  
    &::after {
      display: block;
      content: "";
      background: url(./assets/image/boxBottom.png) no-repeat;
      height: vh(8);
      background-size: cover;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

.marker-container {
  position: relative;
  z-index: 1100;
  .infobox {
    padding: 24px;
    transform: translateX(130px) translateY(-56px);
    position: absolute;
    width: 383px;
    border-radius: 5px;
    min-height: 222px;
    background: rgba(28, 69, 130, 0.7);
    display: none;
    .title {
      font-size: 20px;
      font-family: Microsoft YaHei UI;
      font-weight: bold;
      color: #ffffff;
    }
    .sub-title {
      display: inline-block;
      background: linear-gradient(0deg, #256ee8, #3980f8);
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei UI;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      padding: 0 10px;
      margin-top: 10px;
    }
    .data-list {
      margin-top: 23px;
      .item {
        font-size: 16px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        margin-bottom: 8px;
        .label {
          color: #ffffff;
        }
        .value {
          color: #ff8924;
        }
      }
    }
  }
  .icon-container {
   
    display: flex;
    align-items: center;
   width:115px;
   background: rgba(0, 0, 0, 0.6);
   height: 28px;
    justify-content: center;
   position: relative;

  }

  .maker-icon {
    display: inline-block;
    width:10px;
    height: 10px;
    margin-right:5px;
    &.red {
      background: rgba(204, 0, 0, 0.8);
      color: #fff;
      &::after{
        border-top-color: rgba(204, 0, 0, 0.8);
      }
    }
    &.yellow {
      background: rgba(255, 246, 11, 0.8);
    
    }
    &.green {
      background: rgba(2, 228, 2, 0.8);
    
    }
    &.orange {
      background: rgba(242, 130, 17, 0.8);
    
    }
    border-radius: 50%;
    // animation:myflip infinite;
    // animation-duration: 2s;
  }

  .maker-name {
    display: inline-block;
    width:85px;
    overflow: hidden;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
  }


  /* box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 200px #03e9f4; */
}

/* This was made with GlassGenerator.netlify.app */

.glass-container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  backdrop-filter: blur(13px);
  background-color: rgba(0, 191, 255, 0.027);
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
  border: 2px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
  border-right: 2px rgba(40, 40, 40, 0.35) solid;
}

@keyframes myflip {
  0% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) scale3d(1, 1, 1) translate3d(0, 0, 0)
      rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
</style>
