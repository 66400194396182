<template>
      <div class="screen-footer" >
        <BoxTitle titleText="单位工业增加值能耗强度"  class="custom-box_title"/>
        <div class="charts-container">
          <div class="legend-container">
            <div class="unit">单位：tce/万元</div>
            <!-- <div class="legend-block">
              {{props.year}}年
            </div> -->
          </div>
          <div class="chart-outer">
            <div class="line-charts" ref="linebar" v-chart-resize></div>

          </div>
         
         
        </div>
  </div>
</template>
<script setup>
import BoxTitle from "./BoxTitle.vue";
import gsap from 'gsap'
import { ref,onMounted,watch } from "vue";
import { lineCharts } from "../utils/echarts";
import {getMax} from '@/utils/utils'

const props=defineProps({
  data:Object,
  year:String
})
const linebar=ref(null)
const theData=ref(null)

watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 

});
const initCharts=(val)=>{
  theData.value=val

lineCharts(linebar.value,{
    data: theData.value.dwlh_list,
    max: getMax(theData.value.dwlh_list),
    seriesName:'单位工业增加值能耗强度',
    nameRotate:30
  })
}
onMounted(()=>{
  
})

</script>
<style lang="scss" scoped>
.chart-outer{
 width:vw(850);
}
.screen-footer {
  position: fixed;
  bottom: 0px;
  width: vw(1920);
  height: vh(361);
  background: rgba(27, 48, 102, 0.9);
  text-align: center;
  .legend-container{
    display: flex;
    justify-content: space-between;
    .unit{
      font-size: 13px;
      line-height: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
  
    }
    .legend-block{
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 13px;
      &::before{
        display: inline-block;
        content: '';
        width: vw(12);
        height: vh(12);
        background: #FF8924;
        border-radius: 2px;
        vertical-align: middle;
        margin-right:vw(5);
      }
    }
  }
  .charts-container{
    margin-top:vh(55);
    display: inline-block;
  }
.custom-box_title{
  position: absolute;
  top:vh(2);
  width:vw(880);
  left:50%;
  transform: translateX(-50%);
}
.line-charts{
  height: vh(280);
  width:vw(3000);
}

}
</style>