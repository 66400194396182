<template>
      <div class="screen-footer" >
        <BoxTitle titleText="二氧化碳排放总体情况"  class="custom-box_title"/>
        <div class="charts-container">
          <div class="legend-container">
            <div class="unit">单位：t</div>
            <div class="legend-right">
              <div class="block-container">
                <div class="legend-block blue">
                  碳排放总量
                </div>
                <div class="legend-block orange">
                  碳排放强度
                </div>
                
              </div>
              <div class="unit">单位：t/万元</div>
              </div>
          </div>
          <div class="line-charts" ref="linecharts" v-chart-resize></div>
        </div>
  </div>
</template>
<script setup>
import BoxTitle from "./BoxTitle.vue";
import gsap from 'gsap'
import { ref,onMounted,watch } from "vue";
import { doubleLine } from "../utils/echarts";
import * as echarts from "echarts/core";
import {getMax} from '@/utils/utils'
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,(value,oldValue) => {
  initCharts(value)
 
});
const initCharts=(val)=>{
  theData.value=val
  const value1s=val.down_list.map((item)=>{
    return {name:item.name,value:item.value1}
  })
  const value2s=val.down_list.map((item)=>{
    return {name:item.name,value:item.value2}
  })
  doubleLine(linecharts.value,{
    data1: value2s,
    data2: value1s,
    max: getMax(value1s),
    nameRotate:35
  },{lineColor:'#FF8924',areaColor:new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
        offset: 0,
        color: "rgba(255,137,36,.8)"
    },
    {
        offset: 1,
        color: "rgba(255,137,36, 0)"
    }
], false)})
}
const linecharts=ref(null)
onMounted(()=>{
  
})

</script>
<style lang="scss" scoped>
.screen-footer {
  position: fixed;
  bottom: 0px;
  width: vw(1920);
  height: vh(361);
  background: rgba(27, 48, 102, 0.9);
  text-align: center;
  .block-container{
    display: flex;
  
  }
  .legend-container{
    display: flex;
    justify-content: space-between;
    .unit{
      font-size: 13px;
      line-height: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }
    .legend-right{
      display: flex;
    justify-content: space-between;
  
    .unit{
      
      margin-left:vw(10);
  
    }
    .legend-block{
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 13px;
      margin-left:vw(34);
      &::before{
        display: inline-block;
        content: '';
        width: vw(12);
        height: vh(12);
       
        border-radius: 2px;
        vertical-align: middle;
        margin-right:vw(5);
      
      }
      &.orange{
        &::before{
          background: #FF8924;

        }
      }
      &.blue{
        &::before{
          background: #1ACAE3;

        }
      }
     
    }
    }
   
  }
  .charts-container{
    margin-top:vh(50);
    display: inline-block;
  }
.custom-box_title{
  position: absolute;
  top:vh(2);
  width:vw(880);
  left:50%;
  transform: translateX(-50%);
}
.line-charts{
  height: vh(305);
  width:vw(880);
}

}
</style>