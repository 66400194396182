<template>
  <div class="screen-footer">
    <div class="footer-item floor">
      <div class="item-top" v-if="theData" v-toIntNumber.bar="theData.wsgc_nums">{{ theData.wsgc_nums }}</div>
      <div class="item-bottom">污水处理厂</div>
    </div>
    <div class="footer-item trush">
      <div class="item-top" v-if="theData" v-toIntNumber.bar="theData.fqpf_kou">{{ theData.fqpf_kou }}</div>
      <div class="item-bottom">废气排放口</div>
    </div>
    <div class="footer-item water">
      <div class="item-top" v-if="theData" v-toIntNumber.bar="theData.fspf_kou">{{ theData.fspf_kou }}</div>
      <div class="item-bottom">污水排放口</div>
    </div>
    <div class="footer-item trush">
      <div class="item-top" v-if="theData" v-toIntNumber.bar="theData.gfdf_kou">{{ theData.gfdf_kou }}</div>
      <div class="item-bottom">固废堆放处</div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
// import gsap from 'gsap'
// import { ref,onMounted } from "vue";
// const qiyeNum=ref(0)
// const gasNum=ref(0)
// const waterNum=ref(0)
// gsap.to(qiyeNum,{
//     value:40,
//     duration:2
//   })
//   gsap.to(gasNum,{
//     value:80,
//     duration:2
//   })
//   gsap.to(waterNum,{
//     value:90,
//     duration:2
//   })
const props=defineProps({
  data:Object
})
const theData=ref(null)
watch(()=>props.data,() => {
  theData.value=props.data
 

});
</script>
<style lang="scss" scoped>
.screen-footer {
  position: fixed;
  bottom: 0px;
  width: vw(1920);
  height: vh(173);
  background: rgba(27, 48, 102, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-item {
    width: 205px;
    height:110px;
    padding-top:30px;
    padding-left: 120px;

    &.floor {
      background: url(../assets/image/icon_qiye.png) no-repeat center center;
      .item-top {
        color: #f2f6ff;
        background: linear-gradient(180deg, #4cbaff 0.146484375%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &.trush {
      background: url(../assets/image/icon_trush.png) no-repeat center center;
      .item-top {
        color: #f2f6ff;
        background: linear-gradient(180deg, #d2a51c 0.146484375%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &.water {
      background: url(../assets/image/icon_feishui.png) no-repeat center center;
      .item-top {
        color: #f2f6ff;
        background: linear-gradient(180deg, #4cbaff 0.146484375%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    background-size:200px 115px!important;
    .item-top {
      font-size: 26px;
      line-height: 26px;
      font-family: PangMenZhengDao;
      font-weight: bold;
      font-style: italic;
    }
    .item-bottom {
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>